<template>
    <MainContentComp headerTitle="Add Account">
        <section class="addAccount">
            <div class="error-messageBlock displayNone">
                <span class="icon"><img :src="require('@/assets/img/icons/error.svg')" alt=""></span>
                <p class="text">You have reached the maximum personal Checking Account limit!</p>
            </div>
            <div class="wrapper">
                <h2 class="content__title">Add account</h2>
                <div class="addAccount-block">
                    <form action="#">
                        <div class="default-input item">
                            <label>Label</label>
                            <input id="accountLabel" type="text" v-model="accountLabel">
                        </div>
                        <div class="default-input item" data-select2-id="4">
                            <label>Type</label>
                            <select class="default-select select2-hidden-accessible" data-placeholders="Choose type"
                                name="gender" id="accountType" data-select2-id="accountType" tabindex="-1"
                                aria-hidden="true">
                                <option data-select2-id="2"></option>
                                <option value="personalChecking" data-select2-id="5">Personal account</option>
                            </select><span
                                class="select2 select2-container select2-container--default select2-container--below"
                                dir="ltr" data-select2-id="1" style="width: 126px;"><span class="selection"><span
                                        class="select2-selection select2-selection--single" role="combobox"
                                        aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false"
                                        aria-labelledby="select2-accountType-container"><span
                                            class="select2-selection__rendered" id="select2-accountType-container"
                                            role="textbox" aria-readonly="true" title="Personal account">Personal
                                            account</span><span class="select2-selection__arrow" role="presentation"><b
                                                role="presentation"></b></span></span></span><span
                                    class="dropdown-wrapper" aria-hidden="true"></span></span>
                        </div>
                        <div class="addAccount-check default-check">
                            <input id="styled-checkbox-1" type="checkbox" v-model="isChecked">
                            <label for="styled-checkbox-1">Accept <a target="_blank"
                                    href="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/Client-Config/T%26C+MM/MIMT%26Cs2021.pdf">Terms
                                    &amp; conditions</a></label>
                        </div>
                        <div class="addAccount-buttons">
                            <div class="addAccount-button">
                                <a href="/accountDashboard" class="close">Close</a>
                            </div>
                            <div class="addAccount-button">
                                <a class="close" @click="createAccount">Add</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </MainContentComp>
</template>

<script>
import MainContentComp from '@/components/section/mainComp.vue'
import { addAccount } from '@/api/bankAccount'
export default {
    name: 'AddAccount',
    components: {
        MainContentComp
    },
    props: {
        msg: String
    },
    computed: {
        // currentPath: {
        //   get() {
        //     console.log("this.$route.path: " + this.$route.path)
        //     return this.$route.path; // 获取当前路由路径
        //   }
        // }
    },
    watch: {
        // '$route': { // $route可以用引号，也可以不用引号  监听的对象
        //     handler(to, from) {
        //         this.currentPath = to.path
        //         const pathList = [
        //             '/transactions',
        //             '/Setting'
        //         ]
        //         // console.log("watch router: ", this.currentPath)
        //         if (pathList.indexOf(this.currentPath) > -1) {
        //             this.getUserBankBalance()
        //         }
        //     },
        //     deep: true, // 深度观察监听 设置为 true
        //     immediate: true, // 第一次初始化渲染就可以监听到
        // }
    },
    created() { },
    mounted() { },
    data() {
        return {
            isChecked: false,
            accountLabel: ''
        }
    },
    methods: {
        createAccount() {
            if (this.accountLabel.trim() == "") {
                this.$message.error('Label is Required!');
                return;
            }
            if (!this.validateAlphaNumeric(this.accountLabel)) {
                this.$message.error('label format is wrong! Please enter proper label (e.g: TestCrypto)');
                return;
            }
            if (this.isChecked != true) {
                this.$message.error('Please accept terms of use!');
                return
            }
            this.$pu.showLoading()
            const params = {
                "label": this.accountLabel
            }
            addAccount(params).then(res => {
                this.$pu.hideLoading()
                if (res.code === 200) {
                    this.$message.success('Account created successfully!');
                    let _this = this
                    setTimeout(function () {
                        _this.$router.push("/accountDashboard")
                    }, 1500);
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        },
        validateAlphaNumeric(data) {
            let filter = /^[a-zA-Z0-9_]*$/;
            if (filter.test(data)) {
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.css';

.default-check label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #acaec8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.default-check label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.5px;
    color: #eda858;
    display: flex;
    align-items: center;
}

.default-check label a {
    color: #3246d3;
    display: inline-block;
    margin: 0 4px;
}
</style>