<template>
	<div class="dashboard content" :class="isOpenSidebar ? 'sidebar__open' : ''">
		<aside class="sidebar">
			<SidebarComp @toggleSidebarOpen="toggleSidebarOpen" />
		</aside>
		<main class="main">
			<SectionHeader :header-title="headerTitle" @toggleSidebarOpen="toggleSidebarOpen" />
			<SectionBanner />
			<slot></slot>
		</main>
	</div>
</template>

<script>
	import SectionHeader from '@/components/section/header.vue'
	import SectionBanner from '@/components/section/banner.vue'
	import SidebarComp from '@/components/sidebar/sidebar.vue'
	import {
		getUserBankBalance
	} from '@/api/bankAccount'
	export default {
		name: 'MainContentComp',
		props: {
			headerTitle: {
				type: String,
				default: ''
			}
		},
		components: {
			SidebarComp,
			SectionHeader,
			SectionBanner
		},
		data() {
			return {
				isOpenSidebar: false
			}
		},
		created() {},
		mounted() {
			// console.log('MainContentComp mounted')
		},
		computed: {
			// currentPath: {
			//   get() {
			//     console.log("this.$route.path: " + this.$route.path)
			//     return this.$route.path; // 获取当前路由路径
			//   }
			// }
		},
		watch: {
			'$route': { // $route可以用引号，也可以不用引号  监听的对象
				handler(to, from) {
					this.currentPath = to.path
					const pathList = [
						'/transactions',
						'/Setting',
						'/accountDashboard',
						'/accountDetails',
						'/manageCards',
						'/getCard',
						'/recipients',
						'/feemodule',
						'/currencyEncryption'
					]
					// console.log("watch router: ", this.currentPath)
					if (pathList.indexOf(this.currentPath) > -1) {
						this.getUserBankBalance()
					}
				},
				deep: true, // 深度观察监听 设置为 true
				immediate: true, // 第一次初始化渲染就可以监听到
			}
		},
		methods: {
			toggleSidebarOpen(val) {
				console.log("toggleSidebarOpen: ", val)
				if (undefined == val) {
					this.isOpenSidebar = !this.isOpenSidebar
				} else if (val) {
					this.isOpenSidebar = true
				} else {
					this.isOpenSidebar = false
				}
			},
			getUserBankBalance() {
				getUserBankBalance()
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '@/assets/css/libs.css';
	@import '@/assets/css/style.css';
	@import '@/assets/css/custom.css';
	@import '@/assets/css/main.css';
</style>