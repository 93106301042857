<template>
	<div class="dashboard signUpPage">
		<main>
			<section class="signUp">
				<div class="top">
					<div class="wrapper">
						<a href="#" class="logo"><img
								src="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/logo.svg" alt=""></a>
						<div class="top-right">
							<p class="top-text">Already have an account?</p>
							<a href="/login" class="signIn">
								<span class="icon">
									<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M11.25 16.5H6.75002C5.83502 16.5 5.05502 16.41 4.39502 16.215C4.56002 14.265 6.56252 12.7275 9.00002 12.7275C11.4375 12.7275 13.44 14.265 13.605 16.215C12.945 16.41 12.165 16.5 11.25 16.5Z"
											stroke="white" stroke-width="1.125" stroke-linecap="round"
											stroke-linejoin="round"></path>
										<path class="fill"
											d="M4.395 16.215L4.24178 16.7562C4.40337 16.802 4.57694 16.7731 4.71505 16.6776C4.85315 16.582 4.94134 16.4298 4.9555 16.2624L4.395 16.215ZM13.605 16.215L13.0445 16.2624C13.0587 16.4298 13.1468 16.582 13.285 16.6776C13.4231 16.7731 13.5966 16.802 13.7582 16.7562L13.605 16.215ZM6.75 0.9375C4.81952 0.9375 3.32185 1.32015 2.321 2.321C1.32015 3.32185 0.9375 4.81952 0.9375 6.75H2.0625C2.0625 4.93048 2.42985 3.80315 3.1165 3.1165C3.80315 2.42985 4.93048 2.0625 6.75 2.0625V0.9375ZM0.9375 6.75V11.25H2.0625V6.75H0.9375ZM0.9375 11.25C0.9375 12.713 1.15611 13.9231 1.69494 14.8596C2.2471 15.8192 3.10164 16.4335 4.24178 16.7562L4.54822 15.6738C3.64836 15.419 3.0554 14.9683 2.67006 14.2985C2.27139 13.6057 2.0625 12.622 2.0625 11.25H0.9375ZM4.9555 16.2624C5.08828 14.6931 6.75539 13.29 9 13.29V12.165C6.36961 12.165 4.03172 13.8369 3.8345 16.1676L4.9555 16.2624ZM9 13.29C11.2446 13.29 12.9117 14.6931 13.0445 16.2624L14.1655 16.1676C13.9683 13.8369 11.6304 12.165 9 12.165V13.29ZM13.7582 16.7562C14.8984 16.4335 15.7529 15.8192 16.3051 14.8596C16.8439 13.9231 17.0625 12.713 17.0625 11.25H15.9375C15.9375 12.622 15.7286 13.6057 15.3299 14.2985C14.9446 14.9683 14.3516 15.419 13.4518 15.6738L13.7582 16.7562ZM17.0625 11.25V6.75H15.9375V11.25H17.0625ZM17.0625 6.75C17.0625 4.81952 16.6798 3.32185 15.679 2.321C14.6781 1.32015 13.1805 0.9375 11.25 0.9375V2.0625C13.0695 2.0625 14.1969 2.42985 14.8835 3.1165C15.5702 3.80315 15.9375 4.93048 15.9375 6.75H17.0625ZM11.25 0.9375H6.75V2.0625H11.25V0.9375ZM6.8775 7.93501C6.8775 6.76067 7.82566 5.8125 9 5.8125V4.6875C7.20434 4.6875 5.7525 6.13936 5.7525 7.93501H6.8775ZM9 5.8125C10.1743 5.8125 11.1225 6.76067 11.1225 7.93501H12.2475C12.2475 6.13936 10.7957 4.6875 9 4.6875V5.8125ZM11.1225 7.93501C11.1225 9.11139 10.1723 10.065 9 10.065V11.19C10.7977 11.19 12.2475 9.72863 12.2475 7.93501H11.1225ZM9 10.065C7.82769 10.065 6.8775 9.11139 6.8775 7.93501H5.7525C5.7525 9.72863 7.20231 11.19 9 11.19V10.065Z"
											fill="white"></path>
										<path
											d="M8.99994 10.6275C7.51494 10.6275 6.31494 9.42001 6.31494 7.93501C6.31494 6.45001 7.51494 5.25 8.99994 5.25C10.4849 5.25 11.6849 6.45001 11.6849 7.93501C11.6849 9.42001 10.4849 10.6275 8.99994 10.6275Z"
											stroke="white" stroke-width="1.125" stroke-linecap="round"
											stroke-linejoin="round"></path>
									</svg>
								</span>
								Sign In
							</a>
						</div>
					</div>
				</div>
				<div class="content" style="background-color: #FFFFFF">
					<div class="left" style="background-color: #FFFFFF;background-image: none;">
						<!-- <div class="el el-1"><img :src="require('@/assets/img/dashboardPages/blue_sphere.png')" alt="">
							<span class="el-image"><img
									:src="require('@/assets/img/dashboardPages/signUp-back1-el.png')" alt=""></span>
						</div>
						<div class="el el-2"><img :src="require('@/assets/img/dashboardPages/pink_figure.png')" alt="">
						</div>
						<div class="el el-3"><img :src="require('@/assets/img/dashboardPages/figure_2.png')" alt="">
						</div>
						<div class="el el-4"><img :src="require('@/assets/img/dashboardPages/figure_3.png')" alt="">
						</div>
						<div class="el el-5"><img :src="require('@/assets/img/dashboardPages/sphere_2.png')" alt="">
						</div> -->
						<div class="wrapper" style="background-color: #FFFFFF;">
							<div class="block" style="background-color: #FFFFFF;">
								<div class="info" style="background-color: #FFFFFF;">
									<div class="info-block" style="background-color: #FFFFFF;">
										<div>
											<img :src="require('@/assets/img/login/pic_denglu.png')" alt=""
												style="width: 40%;margin: 50px auto ;">
										</div>
										<h1 class="title">Your payments support UNICEF</h1>
										<h2 class="subtitle">We take a portion of every SEPA Instant and Bankera
											busioness card pament you make ande donate it to help UNICEF.</h2>
									</div>

									<p class="description" id="dateYear"></p>

								</div>
							</div>
						</div>

					</div>
					<div class="right">
						<div class="wrapper">

							<div class="block" v-if="confireUp">



								<h2 class="title">Sign Up</h2>
								<form name="registration" autocomplete="off">

									<input type="hidden" value="" id="countryName" name="countryName">

									<div id="emailErrorShow">
										<span id="EmailError"
											style="color: red; padding-bottom: 10px; text-align: center; display: block;"></span>
									</div>
									<input autocomplete="false" name="hidden" type="text" style="display: none;">
									<div class="block-item default-input">
										<div class="default-input__top">
											<label for="Email" class="label"> Email </label>
										</div>
										<input type="email" class="input" name="email" v-model="email"
											@blur='listenName' maxlength="35" autocomplete="off">
										<div v-if="errorMsg" class="errorMsg">
											Please enter a valid email address.
										</div>
									</div>
									<div class="block-item default-input">
										<div class="default-input__top">
											<label for="countryCode" class="label">Country Code</label>
										</div>
										<el-select class="input" style="padding: 15px 25px;" v-model="value" clearable
											filterable placeholder="Select">
											<template #prefix v-if="value">
												<span class="flex">
													+
												</span>
											</template>
											<el-option v-for="item in options" :key="item.phoneCode"
												:label="item.phoneCode" :value="item.phoneCode">
												<div style="display: flex;align-items: center;">
													<img style="width: 30px;height: 20px;margin-right: 5px;"
														:src="item.logo" alt="">
													<p>{{ item.name }} (+{{ item.phoneCode }})</p>
												</div>
											</el-option>
										</el-select>
									</div>
									<!-- <div class="clearfix"></div> -->

									<div id="errorShowPhone">
										<span id="errorPhone"
											style="color: red; padding-bottom: 10px; text-align: center; display: block;"></span>
									</div>

									<div class="block-item default-input">
										<div id="mobileNumber" class="label">
											<label class="label"> Phone Number ( must be 8 to 11
												digits ) </label>
										</div>

										<input type="text" class="input" id="MobileNo" name="mobileNumber"
											maxlength="11">
									</div>

									<div class="block-items">
										<div class="block-item default-input">
											<div class="default-input__top">
												<label class="label"> Password </label>
											</div>
											<input type="password" class="input" value="" name="password"
												v-model="password" maxlength="15" autocomplete="off">
										</div>
										<div class="block-item default-input">
											<div class="default-input__top">
												<label class="label"> Confirm Password </label>
											</div>
											<input type="password" class="input" value="" @blur="validatePassword()"
												name="confirmPassword" v-model="passwordSecond" maxlength="15"
												autocomplete="off">
										</div>
									</div>
									<!-- <div id="message">
                                        <pre id="letter" class="invalid"
                                            style="text-align: center;">A (lowercase) letter,</pre>
                                        <pre id="capital" class="invalid"
                                            style="text-align: center;">A capital (uppercase) letter,</pre>
                                        <pre id="number" class="invalid" style="text-align: center;">A number,</pre>
                                        <pre id="length" class="invalid"
                                            style="text-align: center;">Minimum 8 characters,</pre>
                                        <pre id="splCharacter" class="invalid"
                                            style="text-align: center;">A Special Character</pre>
                                    </div> -->
									<div class="block-check default-check" style="display: flex;align-items: center;">
										<el-checkbox v-model="isChecked"></el-checkbox>
										<label for="styled-checkbox-1">I agree to <a target="_blank"
												href="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/Client-Config/T%26C+MM/MIMT%26Cs2021.pdf">Terms
												and Condition</a> of this site
										</label>
									</div>
									<span id="mess" style="padding-left: 130px"></span>

									<div class="block-button">
										<div @click="onSubmit()" class="default-button" style="cursor: pointer;">
											Sign Up
										</div>
									</div>
								</form>
							</div>
							<div class="block" style="display: block;" v-if="emailCode">
								<h2 class="title">We sent you a verification code to your email.</h2>
								<h3 class="subtitle">Please enter the code here</h3>
								<form>
									<div class="block-item default-input center">
										<input type="text" class="input" placeholder="OTP" v-model="emailValidateCode">
									</div>
									<div class="block-button">
										<div type="submit" class="default-button" @click="confireSubmit()">Submit</div>
									</div>
								</form>
								<a @click="sengAgain()" class="block-signIn signIn">
									<span class="icon">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M11.1676 3.80993C10.5151 3.61493 9.79506 3.48743 9.00006 3.48743C5.40756 3.48743 2.49756 6.39743 2.49756 9.98993C2.49756 13.5899 5.40756 16.4999 9.00006 16.4999C12.5926 16.4999 15.5026 13.5899 15.5026 9.99743C15.5026 8.66243 15.0976 7.41743 14.4076 6.38243"
												stroke="white" stroke-width="1.125" stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path d="M12.0974 3.99L9.92993 1.5" stroke="white" stroke-width="1.125"
												stroke-linecap="round" stroke-linejoin="round"></path>
											<path d="M12.0976 3.99005L9.57007 5.83505" stroke="white"
												stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round">
											</path>
										</svg>
									</span>
									Resend OTP
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import {
	ElMessage
} from 'element-plus';
import {
	getNational,
	registerBank,
	sendEmailCode
} from "@/api/login";
export default {
	name: 'LoginComp',
	components: {

	},
	created() { },
	mounted() {
		this.getNationalData()
	},
	watch: {
		email: {
			handler(newVal, orival) {
				if (newVal != '') {
					this.clearImg = true
				} else {
					this.clearImg = false
					this.errorMsg = false
				}
			}
		}
	},
	computed: {
		// currentPath: {
		//   get() {
		//     console.log("this.$route.path: " + this.$route.path)
		//     return this.$route.path; // 获取当前路由路径
		//   }
		// }
	},
	data() {
		return {
			options: [],
			value: '',
			password: '',
			passwordSecond: '',
			email: '',
			isChecked: false,
			errorMsg: false,
			emailCode: false,
			confireUp: true,
			emailValidateCode: ''
		}
	},
	methods: {
		listenName() {
			var email = this.email;
			var reg = /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
			if (reg.test(email) || email == '') {
				this.errorMsg = false
			} else {
				this.errorMsg = true
			}
		},
		validatePassword() {
			if (this.password != this.passwordSecond) {
				ElMessage.error('Not Matching')
				return
			}
		},
		async getNationalData() {
			let res = await getNational();
			this.options = res.data;
			// this.changePhoneCountry(this.ruleForm.nationalId)
			// this.getphoneCountryLogo(val)
		},
		confireSubmit() {
			let data = {
				password: this.password,
				passwordSecond: this.passwordSecond,
				email: this.email,
				emailValidateCode: this.emailValidateCode,
				alph2: this.value,
				useEmailValidateCode: 1
			}
			registerBank(data).then(res => {
				if (res.code == 200) {
					ElMessage.success({
						message: res.msg,
						type: 'success',
					})
					this.$router.push('/login');
				} else {
					ElMessage.error(res.msg)
				}
			})
		},
		onSubmit() {
			if (!this.email) {
				ElMessage.error('please enter the Email')
				return
			}
			if (!this.password) {
				ElMessage.error('please enter the PassWord')
				return
			}
			if (!this.isChecked) {
				ElMessage.error('please agree to Terms and Condition of this site')
				return
			}
			let datalist = {
				email: this.email,
				moduleType: 1
			}
			sendEmailCode(datalist).then(res => {
				if (res.code == 200) {
					this.emailCode = true
					this.confireUp = false
					ElMessage.success({
						message: res.msg,
						type: 'success',
					})
				}
			})
		},
		sengAgain() {
			let datalist = {
				email: this.email,
				moduleType: 1
			}
			sendEmailCode(datalist).then(res => {
				if (res.code == 200) {
					ElMessage.success({
						message: res.msg,
						type: 'success',
					})
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/libs.css';
@import '@/assets/css/signUp.css';
@import '@/assets/css/dashboard.css';
@import '@/assets/css/custom.css';
@import '@/assets/css/intlTelInput.css';
@import '@/assets/css/HoldOn.min.css';
@import '@/assets/css/select2.min.css';
@import '@/assets/css/toastr.min.css';
</style>
<style lang="scss" scoped>
:deep(.el-select__wrapper) {
	color: #fff !important;
	padding: 0;
	background: transparent !important;
	box-shadow: none !important;
}

:deep(.default-input input) {
	border-radius: 15px;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: -0.5px;
	color: #072761;
	background: transparent;
	width: 100%;
}

:deep(.el-select__placeholder) {
	color: #072761 !important;
}

.default-button {
	background: #7F7F81
}

.default-check label:before {
	display: none;
}

:deep(.el-checkbox) {
	margin-right: 5px;
}

.errorMsg {
	font-size: 14px;
	font-family: PingFang SC-Medium, PingFang SC;
	font-weight: 400;
	color: #E63232;
}

.signUp {
	.right {
		background-image: none;
		background-color: #f5f6fa;

		.wrapper {
			background-color: #f5f6fa;
		}

		.title {
			color: #072761;
		}
	}
}

.default-input {
	.label {
		color: #072761;
	}

	input {
		color: #072761;
		border: 1px solid #072761;
	}

	.input {
		color: #072761;
		border: 1px solid #072761;
	}
}

.signUp {
	.top {
		background-color: transparent;

		.top-text {
			color: #072761;
		}
	}

	.signIn {
		color: #072761;
	}
}


.signUp {
	.left {
		.title {
			font-size: 32px;
			font-family: Microsoft YaHei-Bold, Microsoft YaHei;
			color: #165ba4;
			font-weight: 700;
			text-align: center;
			margin-bottom: 30px;
		}

		.subtitle {
			font-size: 32px;
			font-family: Microsoft YaHei-Bold, Microsoft YaHei;
			color: #165ba4;
			font-weight: 700;
			text-align: center;
			margin-bottom: 30px;
			font-size: 16px;
			color: rgb(111, 125, 141);
			text-align: center;
			line-height: 28px;
		}
	}
}

:deep() {
	.default-check {
		label {
			color: #072761;

			a {
				color: #072761;
			}
		}
	}
}
</style>