<template>
    <el-dialog
     class="mobilPhone" style="border-radius: 20px;margin-top: 15vh;" width="600px" 
     	:sureText="sendType == 'first'?'Confirm':''"
     	:visible="visible"
     	:header="false"
     	:closeBtn="false"
     	:btnloading="btnloading"
     	@onClose="onClose"
     	@onSure="sendType == 'first'?onSure('form'):''"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-if="sendType == 'first'" name="first">
          <span slot="label" class="disal">
            <!-- <img
              :src="require('@/assets/imgs/icon_fasong_01.png')"
              class="mr10"
            /> -->
            Send
            <img width="25px"
              :src="sendData.coinLogo"
              class="ml10 mr10"
            />
            {{sendData.coinName }}
          </span>
        </el-tab-pane>
        <el-tab-pane v-if="sendType == 'second'" name="second">
          <span slot="label" class="disal">
            <!-- <img
              :src="require('@/assets/imgs/icon_jiehsou_02.png')"
              class="mr10"
            /> -->
             Deposit
            <img width="25px"
              :src="sendData.coinLogo"
              class="ml10 mr10"
            />
            {{sendData.coinName }}
          </span>
        </el-tab-pane>
        <div></div>
      </el-tabs>
      <div class="change-form">
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <div v-if="activeName == 'first'">
              <el-form-item label="tokens" prop="coinName">
            <el-select
              class="svg-container"
              v-model="form.coinName"
              placeholder="Please select"
              @change="changeCoin(form.coinName)"
              disabled
            >
              <template #prefix>
                <span class="flex">
                  <img
                    :src="choiceCoin.coinLogo || require('@/assets/imgs/icon_btc.png')"
                    width="24"
                    height="24"
                  />
                </span>
              </template>
              <el-option
                v-for="item in option"
                :key="item.id"
                :label="item.coinName"
                :value="item.id"
              >
                <div class="disal" >
                  <img
                    :src="item.coinLogo"
                    width="24"
                    height="24"
                  />
                  <span class="select-item">{{ item.coinName }}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Withdrawal Network" prop="coinNetWork">
            <el-select
              v-model="form.coinNetWork"
              placeholder="Please select"
              @change="changeCoinLink"
            >
              <el-option
                v-for="item1 in CoinLinkOption"
                :key="item1.coinLink"
                :label="item1.coinLink"
                :value="item1.coinLink"
              >
                <div class="disal" style=" font-size: 14px;">
               <!--  <img
                    :src="item1.coinLogo"
                    width="24"
                    height="24"
                  /> -->
                  <span class="select-item">{{ item1.coinLink }}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <div>
            <el-form-item
              label="Withdrawal Address"
              prop="transferWalletAddress"
            >
              <el-input
                v-model="form.transferWalletAddress"
                placeholder="Please enter the withdrawal address"
              ></el-input>
            </el-form-item>
            <el-form-item label="Withdrawal Amount" prop="coinAmount">
              <el-input
                v-model="form.coinAmount"
                placeholder="Please enter quantity"
                @input="changeAmount1"
              >
                <template #append>
                  <div class="disib">{{form.coinName}}</div>
                  <div class="disib ml5 mr5">|</div>
                  <div class="disib common-color" @click="choiceAll">All</div>
                </template>
              </el-input>
            </el-form-item>
            <div class="mb15">
              <span class="account-balance">Account Balance</span>
              <span class="account-num" v-if="balance">{{ balance }} {{form.coinName}}</span>
            </div>
            <div v-if="form.coinAmount" class="two-between mb15">
              <div  class=" account-num1">
              <span>Transaction fees</span>
              <span>&nbsp;{{ transferAmount }} {{form.coinName}}</span>
            </div>
            <div class=" account-num1">
              <span>Actual Amount Received</span>
              <span>&nbsp;{{ subtr(form.coinAmount, transferAmount) }} {{form.coinName}}</span>
            </div>
            </div>
            
          </div>
          </div>
          <div v-if="activeName == 'second'">
            <div class="google-code">
               <vue-qr :value="sendData.walletAddress" style="width: 300px;height: 300px;" :size="200"></vue-qr>
            </div>
            <div class="two-between two-between1 mt15">
              <!-- <span class="google-address">{{translateTitle('地址')}}</span> -->
              <span class="google-code-num">{{ sendData.walletAddress }}</span>
              <img
                :src="require('@/assets/imgs/ico_copy@2x.png')"
                width="25"
                class="cp"
                @click="onCopy(sendData.walletAddress)"
              />
            </div>
          </div>
		   <div class="dialog-footer" v-if="sendType == 'first'">
		          <el-button type="primary" @click="onSure('form')">
		            Confirm
		          </el-button>
		        </div>
        </el-form>
      </div>
    </el-dialog>
</template>

<script>
import { coinNames, coinLink, coinBalanceUSD, getTransferRate, calculateCharge } from "@/api/index.js";
import { coinSend, coinBalance, reciveCoin } from "@/api/wallet.js";
import { translateTitle } from "@/utils/i18n";
import {multiply,subtr,division} from '@/utils/date'
import VueQr from '@chenfengyuan/vue-qrcode'
import QRCode from 'qrcode'
export default {
  name: "sendDiaolg",
  model: {
    prop: "visible",
    event: "visibleEvent",
  },
  components: { VueQr },
  props: {
    // 显示/隐藏
    visible: {
      type: Boolean,
      default: false,
    },
    sendType: {
      type: String,
      default: 'first'
    },
    sendDatalsit: {
      type: Object,
      default: {}
    }
  },
  data() {
    const validateAmount = (rule, value, callback) => {
      const num= /(^[0-9]\d*(\.\d{1,100})?$)|(^0(\.\d{1,100})?$)/;
      if (!num.test(value)) {
        callback(new Error('Please enter the correct format.'))
      }else{
        callback()
      }
    }
    return {
      option: [],
      cities: [],
      balance: "",
      codeText: "",
      choiceCoin: {
        coinName: '',
        coinLogo: "",
      },
      CoinLinkOption: [],
      form: {
        coinAmount: "",
        coinName: "",
        coinNetWork: "",
        transferWalletAddress: "",
      },
      rules: {
        coinAmount: [
          {
            required: true,
            message: 'Please enter withdrawal amount',
            trigger: "blur",
          },
          { required: true, validator: validateAmount, trigger: "change" }
        ],
        coinName: [
          {
            required: true,
            message: 'Please select tokens',
            trigger: "change",
          },
        ],
        transferWalletAddress: [
          {
            required: true,
            message: 'Content copied to clipboard',
            trigger: "blur",
          },
        ],
        idNumber: [
          {
            required: true,
            message: 'Please enter ID No.',
            trigger: "blur",
          },
        ],
        coinNetWork: [
          {
            required: true,
            message: 'Please select withdrawal network',
            trigger: "change",
          },
        ],
        idExpire: [
          {
            required: true,
            message: 'Please select expiry date',
            trigger: "change",
          },
        ],
      },
      activeName: "first",
      btnloading: false,
      to_usdt_rate: '0',
      coinAmountUsdt: '',
      transferRate: '0',
      transferAmount: '0',
      globalLoading: null,
      lgoImg: '',
    };
  },
  created() {
	this.sendData = JSON.parse(JSON.stringify(this.sendDatalsit))
    this.getCoinName();
    this.activeName = this.sendType
    // this.getcoinBalance(this.sendData)
    this.form.coinName = this.sendData.coinName
    this.form.coinNetWork = this.sendData.coinLink
    // this.getCoinLink(this.sendData.coinName);
  },
  methods: {
    multiply,
    subtr,
    division,
    changeAmount1(params) {
     if (params == '') { this.transferAmount = ''; } else {
       this.getTransferAmount(params)
        //  this.transferAmount = this.multiply(this.transferRate,params);
          }
    },
    async getCoinName() {
      let res = await coinNames();
      this.option = res.data;

    this.changeCoin(this.sendData.coinName)
    },
    async getCoinLink(val) {
      let res = await coinLink(val);
      this.CoinLinkOption = res.data;
    },
     getcoinBalance(val) {
       let valueCoin = JSON.parse(JSON.stringify(val))
            let params = {
              coinName: valueCoin.coinName,
              coinLink: valueCoin.coinNetWork,
            };
      coinBalanceUSD(params).then((res) => {
        if (res.code == 200) {
          this.to_usdt_rate = res.data.to_usdt_rate;
          this.balance = res.data.balance;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getTransferAmount(val) {
      let params = {
         coinLink: this.form.coinNetWork,
         coinName: this.form.coinName,
         inputAmount: val || 0,
         type: '1'
       }
       calculateCharge(params).then(res => {
          this.transferAmount = res.data.charge
       })
    },
    changeCoinLink() {
        let valueCoin = JSON.parse(JSON.stringify(this.form))
            this.getcoinBalance(valueCoin);
    },
    onSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.btnloading = true
          this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
          if (this.activeName == "first") {
            coinSend(this.form).then((res) => {
              if (res.code == 200) {
                this.$message.success("Successful");
                // this.getData()
                this.visible = true;
                this.$refs["form"].resetFields();
                this.$emit("visibleEvent", false);
              } else {
                this.$message.error(res.msg);
              }
            }).finally(() => {
              // this.btnloading = false
              this.globalLoading.close()
            });
          } else {
            let params = {
              coinLink: this.sendData.coinLink,
              coinName: this.sendData.coinName,
            };
            reciveCoin(params).then((res) => {
              if(res.code == 200) {
                this.codeText = res.data.walletAddress;
              } else {
                this.$message.error(res.msg);
              }
            }).finally(() => {
              this.btnloading = false
            });
          }
        } else {
          return false;
        }
      });
    },
    // 取消
    onClose() {
      this.$emit("visibleEvent", false);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    changeCoin(data) {
        console.log(data)
        console.log(this.option)
      let obj = this.option.find(item => item.coinName === data)
      console.log(obj)
      this.choiceCoin.coinName = obj.coinName;
      this.choiceCoin.coinLogo = obj.coinLogo;
      this.form.coinName = obj.coinName;
      this.form.coinNetWork = obj.coinLink
      this.getcoinBalance(this.form);
      this.getCoinLink(this.choiceCoin.coinName);
    },
    choiceAll() {
      this.form.coinAmount = this.balance;
      this.getTransferAmount(this.form.coinAmount)
    },
    onCopy(text) {
      if (navigator.clipboard) {
        // clipboard api 复制
        navigator.clipboard.writeText(text);
      } else {
        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        // 隐藏此输入框
        textarea.style.position = "fixed";
        textarea.style.clip = "rect(0 0 0 0)";
        textarea.style.top = "10px";
        // 赋值
        textarea.value = text;
        // 选中
        textarea.select();
        // 复制
        document.execCommand("copy", true);
        // 移除输入框
        document.body.removeChild(textarea);
      }
      this.$message.success("The content copied to clipboard");
    },
  },
};
</script>

<style lang="scss" scoped>
	@media (max-width: 480px) {
	 .google-code-num{
	 	  white-space: normal;
	 	  overflow-wrap: break-word;
		  width: 90%;
	 }
	}
	:deep(.el-tabs__header){
		display: none;
	}
	:deep(.dialog-footer){
		    /* float: right; */
		    display: flex;
		    /* align-items: flex-end; */
		    justify-content: flex-end;
		    margin-top: 20px;
	}
	.disal{
		    display: flex;
		    align-items: center;
			font-size: 24px;
			    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			    font-weight: bold;
			    color: #6F7D8D;
	}
  .el-tabs__item {
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #6F7D8D;
  }
  .el-tabs__item.is-active {
    color: #31363e;
  }
  .el-tabs__nav-wrap::after {
    height: 0;
  }
  .el-tabs__active-bar {
    width: 76px;
    height: 4px;
    background: #786234;
  }
.change-form {
		background: #eff6ff;
		padding: 30px;
		margin: 20px 0;
		border-radius: 10px;
	}
  .el-dialog__body {
    padding: 10px 20px;
  }
  .el-input-group__append {
    background-color: #fff;
    border: none;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #b9bfca;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      height: 46px;
      width: 1px;
      background-color: #eee;
      top: 12px;
      left: 0;
    }
  }
.select-item {
  // font-size: 18px;
  // font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  // font-weight: 400;
  // color: #31363e;
  margin-left: 10px;
}
 .change-form.el-form-item.col2 .el-input{
    width: 46%;
  }
  .change-form.el-form-item.col2 .jiange {
    padding: 0 3%;
    line-height: 56px;
  }
 .change-form .svg-container1 .el-input__inner {
    padding-left: 15px !important;
 }
  ::v-deep { .el-input-group__append::after {
    background-color: transparent !important;
  }
  }
 .change-form .account-balance {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #31363E;
    margin-right: 20px;
  }
 .change-form .account-num {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #31363E;
  }
 .change-form .account-num1 {
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: rgba(49,54,62,0.6);
  }
 .change-form .google-code {
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
  }
 .change-form .google-address {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #b9bfca;
  }
 .change-form .two-between1 {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 15px;
    padding: 15px;
	    display: flex;
	    align-items: center;

  }
 .change-form .google-code-num {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #31363e;
  }
  :deep(.el-select__wrapper){
	 height: 56px !important;
	     line-height: 56px !important;
	     border-color: transparent;
	     border-radius: 8px;
		 box-shadow: none;
  }
  :deep(.el-select__wrapper.is-disabled){
	  box-shadow: none;
  }
  :deep(.el-input__wrapper){
	  height: 56px !important;
	      line-height: 56px !important;
	      border-color: transparent;
	      border-radius: 8px;
		  box-shadow: none;
  }
  :deep(.el-input-group__append){
	      background-color: #fff;
	      border: none;
	      font-size: 14px;
	      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
	      font-weight: 400;
	      color: #b9bfca;
	      cursor: pointer;

  }
  .common-color{
	      color: #165BA4 !important;
  }
  .ml5 {
      margin-left: 5px;
  }
  .mr5 {
      margin-right: 5px;
  }
  :deep(.el-input-group__append){
	  box-shadow: none;
  }
</style>
