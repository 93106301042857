import request from "@/utils/request";

//发送邮箱验证码
export const sendEmailCode = (data) =>
  request({
    url: "/code/sendEmailCode",
    method: "post",
    data,
  });

//发送手机验证码
export const sendPhoneCode = (data) =>
  request({
    url: "/code/sendPhoneCode",
    method: "post",
    data,
  });
//币种
export const coinNames = () =>
  request({
    url: "/coin/coinNames",
    method: "GET",
  });
//查询发行的代币 
export const sysCoins = () =>
  request({
    url: "/coin/sysCoins",
    method: "GET",
  });
  
  //查询币种支持的网络（链）
  export const coinLink = (coinName) =>
  request({
    url: `/coin/coinLink/${coinName}`,
    method: "GET",
  });
//查询用户未拥有的币种
  export const coinNotHasNames = () =>
  request({
    url: "/coin/coinNotHasNames",
    method: "GET",
  });

  //查询系统所有币种
  export const supportCoinNames = () =>
  request({
    url: "/coin/supportCoinNames",
    method: "GET",
  });
//账户-预估余额

export const getBalanceCoinNam = (coinName) =>
  request({
    url: `/account/getBalance/${coinName}`,
    method: "GET",
  });
 //
  //查询银行卡余额
  export const userBankBalance = () =>
  request({
    url: `/account/userBankBalance`,
    method: "GET",
  });
//
export const accountTotal = () =>
  request({
    url: `/account/total`,
    method: "GET",
  });
  //加密账户USDT总余额
export const totalUsdtBalance = () =>
  request({
    url: `/account/totalUsdtBalance`,
    method: "GET",
  });
  
  //加密货币币种余额-不转USD
  export const coinBalanceUSD = (data) =>
  request({
    url: "/user/wallet/coinBalance",
    method: "post",
    data,
  });
   //加密账户余额预估-USD
  export const accountGetBalance = (data) =>
  request({
    url: "/account/getBalance",
    method: "post",
    data,
  });
  
//查询用户是否开启google验证。
export const isOpenGoogleAuth = (data) =>
  request({
    url: "/l/isOpenGoogleAuth",
    method: "post",
    data,
  });
//费率/
export const getTransferRate = (data) =>
  request({
    url: `/user/rate/getTransferRate`,
    method: "post",
    data,
  });
//计算手续费
export const calculateCharge = (data) =>
  request({
    url: `/user/rate/calculateCharge`,
    method: "post",
    data,
  });
  //计算汇率
  export const rechargeCoins = (data) =>
    request({
      url: `/coin/rechargeCoins`,
      method: "post",
      data,
    });
