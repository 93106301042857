import { createStore } from 'vuex'

const store = createStore({
    state() {
        return {
            loading: false,
            userInfo: {
            },
            assets:{
                userBankBalance:'0'
            },
            personalInfo:{

            },
            hasGlobalAccount:undefined
        }
    },
    mutations: {
        //tf作为主动控制的参数
        switch_loading(state, tf) {
            // console.log("tf: ", tf)
            if (tf != undefined || tf != null) {
                state.loading = tf;
            } else {
                state.loading = !state.loading
            }
        },
        set_userInfo(state, data) {
            // console.log("set_userInfo: ", JSON.stringify(data, null, '\t'))
            if (data != undefined || data != null) {
                state.userInfo = data
            } else {
                state.userInfo = {}
            }
        },
        set_assets_info(state, data) {
            // console.log("set_assets_info: ", JSON.stringify(data, null, '\t'))
            if (data != undefined || data != null) {
                state.assets = data
            } else {
                state.assets = {}
            }
        },
        set_has_global_account(state, data) {
            // console.log("set_has_global_account: ", JSON.stringify(data, null, '\t'))
            if (data != undefined || data != null) {
                state.hasGlobalAccount = data
            } else {
                state.hasGlobalAccount = false
            }
        },
        set_personal_info(state, data){
            // console.log("set_personal_info: ", JSON.stringify(data, null, '\t'))
            if (data != undefined || data != null) {
                state.personalInfo = data
            } else {
                state.personalInfo = false
            }
        },
        logout(state, data) {
            // console.log("logout: ")
            state.userInfo = {}
            state.assets = {}
            state.hasGlobalAccount = undefined
        },
    }
})

export default store;