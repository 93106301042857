<template>
    <MainContentComp headerTitle="Profile">
        <section class="account__content block block__active">
            <div class="wrapper">
                <div class="profile-adress profile-info" :class="isShowAddressEditPanel ? 'noActive' : ''">
                    <el-tabs v-model="activeName" @tab-click="handlerTabClick">
                        <el-tab-pane label="KYC Info" name="kycInfo">

                        </el-tab-pane>
                        <el-tab-pane label="Personal Info" name="personalInfo">

                        </el-tab-pane>
                        <el-tab-pane label="Address Info" name="addressInfo">

                        </el-tab-pane>
                    </el-tabs>
                    <!-- <div class="account__content-top">
                        <div class="tabs">
                            <div class="tab active">KYC Info</div>
                            <div class="tab personal-info" onclick="fetchUser()">Personal
                                Info</div>
                            <div class="tab address-info" onclick="fetchUser()">Address
                                Info</div>


                        </div>
                    </div> -->
                    <div class="container" :class="activeName == 'kycInfo' ? 'active' : ''">
                        <div class="wrapper">

                            <table class="table-kyc" cellpadding="0" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Document Type</th>
                                        <th>KYC Status</th>
                                        <th>Action</th>
                                        <th>Compliance Status</th>
                                    </tr>
                                </thead>
                                <tbody id="kycdoc">
                                    <tr>
                                        <td id="email">{{ kycStatus.email }}</td>
                                        <td id="documentType">{{ kycStatus.documentType }}</td>
                                        <td>
                                            <a id="kycStatus"> {{ kycStatus.kycStatus }}</a>
                                        </td>
                                        <td id="documentType_action">
                                            <a id="documentType_action" class="fx-wrap" @click="kycInitiateClick">
                                                <img :src="require('@/assets/img/icons/upload-blue.svg')" alt="info">
                                                <span style="margin-left: 6px;">Initiate KYC</span>
                                            </a>
                                        </td>
                                        <td>
                                            <a id="complianceStatus">{{ kycStatus.complianceStatus }}</a>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>
                    </div>
                    <div class="container" :class="activeName == 'personalInfo' ? 'active' : ''">
                        <div class="block">
                            <form action="#" id="personalInfoForm">
                                <div class="items">
                                    <div class="default-input item">
                                        <label>First Name</label>
                                        <div class="input">
                                            <input type="text" id="firstName" maxlength="50"
                                                v-model="personalInfo.firstName">
                                        </div>
                                    </div>
                                    <div class="default-input item">
                                        <label>Middle Name </label>
                                        <div class="input">
                                            <input type="text" id="MiddleName" v-model="personalInfo.middleName"
                                                maxlength="50">
                                        </div>
                                    </div>
                                    <div class="default-input item">
                                        <label>Last Name</label>
                                        <div class="input">
                                            <input type="text" id="lastName" v-model="personalInfo.lastName"
                                                maxlength="50">
                                        </div>
                                        <!-- <p class="message">You cannot use numbers in this field</p> -->
                                    </div>
                                </div>
                                <div class="items">
                                    <div class="default-input item">
                                        <label>Date of Birth</label>
                                        <div class="input" style="padding: 8px 20px;">
                                            <!-- <input class="customReadOnlyInputBackground" type="text" id="dateOfBirth"
                                                v-model="personalInfo.dateOfBirth" readonly="readonly"> -->
                                            <el-date-picker value-format="YYYY-MM-DD" style="width: 100%;border:0;box-shadow: 0;" :editable="false" :clearable="false"
                                                v-model="personalInfo.dateOfBirth" type="date" placeholder="Select date" prefix-icon="none" class="date-of-birth">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                    <div class="default-input item">
                                        <label>Email</label>
                                        <div class="input">
                                            <input class="customReadOnlyInputBackground" type="text" id="email2"
                                                v-model="personalInfo.email" readonly="readonly">
                                        </div>
                                    </div>
                                    <div class="default-input item">
                                        <label>Mobile Country Code</label>
                                        <el-select v-model="personalInfo.phoneCountryCode" placeholder="">
                                            <el-option v-for="item in mobileCountryCodeOptions" :key="item.phoneCode"
                                                :label="item.name + '(' + item.phoneCode + ')'" :value="item.phoneCode">
                                            </el-option>
                                        </el-select>
                                    </div>


                                </div>
                                <div class="items">
                                    <div class="default-input item">
                                        <label>idType</label>
                                        <el-select v-model="personalInfo.idType" placeholder="">
                                            <el-option v-for="item in idTypeList" :key="item.label" :label="item.label"
                                                :value="item.label">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="default-input item">
                                        <label>idNumber</label>
                                        <div class="input">
                                            <input type="text" id="idNumber" v-model="personalInfo.idNumber"
                                                maxlength="11">
                                        </div>
                                    </div>
                                    <div class="default-input item">
                                        <label>Phone Number</label>
                                        <div class="input">
                                            <input type="text" id="phoneNumber" v-model="personalInfo.phone"
                                                maxlength="11">
                                        </div>
                                    </div>
                                </div>
                                <button type="button" class="button" @click="updatePersonalInfo">Save
                                    Changes</button>
                            </form>
                        </div>
                    </div>

                    <div class="container" :class="activeName == 'addressInfo' ? 'active' : ''">
                        <div class="info">
                            <div class="field">
                                <p class="description">Delivery Address</p>
                                <p class="adress" id="deliveryAddress">
                                    {{ deliveryAddress }}
                                </p>
                                <p class="description">Billing Address</p>
                                <p class="adress" id="billingAddress">
                                    {{ deliveryAddress }}
                                </p>
                                <div class="edit" @click="isShowAddressEditPanel = true">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.0504 3.00002L4.20878 10.2417C3.95045 10.5167 3.70045 11.0584 3.65045 11.4334L3.34211 14.1333C3.23378 15.1083 3.93378 15.775 4.90045 15.6084L7.58378 15.15C7.95878 15.0834 8.48378 14.8084 8.74211 14.525L15.5838 7.28335C16.7671 6.03335 17.3004 4.60835 15.4588 2.86668C13.6254 1.14168 12.2338 1.75002 11.0504 3.00002Z"
                                            stroke="#3246D3" stroke-width="1.25" stroke-miterlimit="10"
                                            stroke-linecap="round" stroke-linejoin="round">
                                        </path>
                                        <path d="M9.9082 4.20831C10.2665 6.50831 12.1332 8.26665 14.4499 8.49998"
                                            stroke="#3246D3" stroke-width="1.25" stroke-miterlimit="10"
                                            stroke-linecap="round" stroke-linejoin="round">
                                        </path>
                                        <path d="M2.5 18.3333H17.5" stroke="#3246D3" stroke-width="1.25"
                                            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="mailing profile-info" :class="isShowAddressEditPanel ? 'active' : ''">
                    <div class="account__content-top">
                        <h2 class="content__title">Address Info</h2>
                    </div>

                    <div class="block">
                        <form action="#" id="addressInfoForm">
                            <div class="items">
                                <div class="default-input item">
                                    <label>Delivery Country</label>
                                    <el-select v-model="addressInfo.deliveryCountry" placeholder="">
                                        <el-option v-for="item in countryCodeOptions" :key="item.alpha2"
                                            :label="item.name + '(' + item.countryCode + ')'" :value="item.alpha2">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="default-input item">
                                    <label>Delivery Address 1</label>
                                    <div class="input">
                                        <input type="text" id="deliveryAddress1" v-model="addressInfo.deliveryAddress">
                                    </div>
                                </div>
                                <div class="default-input item">
                                    <label>Delivery Address 2</label>
                                    <div class="input">
                                        <input type="text" id="deliveryAddress2"
                                            v-model="addressInfo.deliveryAddressSecond">
                                    </div>
                                </div>

                            </div>

                            <div class="items">
                                <div class="default-input item">
                                    <label>Delivery City</label>
                                    <div class="input">
                                        <input type="text" id="deliveryCity" v-model="addressInfo.deliveryCity">
                                    </div>
                                </div>
                                <div class="default-input item">
                                    <label>Delivery State</label>
                                    <div class="input">
                                        <input type="text" id="deliveryState" v-model="addressInfo.deliveryState">
                                    </div>
                                </div>
                                <div class="default-input item">
                                    <label>Delivery Zip Code</label>
                                    <div class="input">
                                        <input type="text" id="deliveryZipCode" v-model="addressInfo.deliveryZipCode">
                                    </div>
                                </div>
                            </div>

                            <div class="items">
                                <div class="default-input item">
                                    <label>Billing Country</label>
                                    <el-select v-model="addressInfo.billingCountry" placeholder="">
                                        <el-option v-for="item in countryCodeOptions" :key="item.alpha2"
                                            :label="item.name + '(' + item.countryCode + ')'" :value="item.alpha2">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="default-input item">
                                    <label>Billing Address 1</label>
                                    <div class="input">
                                        <input type="text" id="billingAddress1" v-model="addressInfo.billingAddress">
                                    </div>
                                </div>
                                <div class="default-input item">
                                    <label>Billing Address 2</label>
                                    <div class="input">
                                        <input type="text" id="billingAddress2"
                                            v-model="addressInfo.billingAddressSecond">
                                    </div>
                                </div>

                            </div>

                            <div class="items">
                                <div class="default-input item">
                                    <label>Billing City</label>
                                    <div class="input">
                                        <input type="text" id="billingCity" v-model="addressInfo.billingCity">
                                    </div>
                                </div>
                                <div class="default-input item">
                                    <label>Billing State</label>
                                    <div class="input">
                                        <input type="text" id="billingState" v-model="addressInfo.billingState">
                                    </div>
                                </div>
                                <div class="default-input item">
                                    <label>Billing Zip Code</label>
                                    <div class="input">
                                        <input type="text" id="billingZipCode" v-model="addressInfo.billingZipCode">
                                    </div>
                                </div>
                            </div>
                            <div class="buttons">
                                <button type="button" class="button" @click="updateAddressInfo"
                                    id="addressUpdateButton">Save
                                    Changes</button>
                                <div class="cancel" @click="isShowAddressEditPanel = false">Cancel</div>
                            </div>

                        </form>
                    </div>
                    <div class="container">
                        <div class="info">
                            <div class="field">
                                <div class="default-input item">
                                    <label>Billing City</label>
                                    <div class="input">
                                        <input type="text" id="billingCity">
                                    </div>
                                </div>
                                <div class="default-input item">
                                    <label>Billing State</label>
                                    <div class="input">
                                        <input type="text" id="billingState">
                                    </div>
                                </div>
                                <div class="default-input item">
                                    <label>Billing Zip Code</label>
                                    <div class="input">
                                        <input type="text" id="billingZipCode">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </section>
    </MainContentComp>
</template>

<script>
import MainContentComp from '@/components/section/mainComp.vue'
import { getPersonalInfo, updatePersonalInfo } from '@/api/user'
import { getNational } from '@/api/commonOption'
import { getUserKycDetails } from '@/api/kyc'
export default {
    inject: ['reload'],
    name: 'ProfileComp',
    components: {
        MainContentComp
    },
    props: {
        msg: String
    },
    computed: {
        // currentPath: {
        //   get() {
        //     console.log("this.$route.path: " + this.$route.path)
        //     return this.$route.path; // 获取当前路由路径
        //   }
        // }
    },
    watch: {
        // '$route': { // $route可以用引号，也可以不用引号  监听的对象
        //     handler(to, from) {
        //         this.currentPath = to.path
        //         const pathList = [
        //             '/transactions',
        //             '/Setting'
        //         ]
        //         // console.log("watch router: ", this.currentPath)
        //         if (pathList.indexOf(this.currentPath) > -1) {
        //             this.getUserBankBalance()
        //         }
        //     },
        //     deep: true, // 深度观察监听 设置为 true
        //     immediate: true, // 第一次初始化渲染就可以监听到
        // }
    },
    created() { },
    mounted() {
        this.initData()
    },
    data() {
        return {
            activeName: 'kycInfo',//'kycInfo','personalInfo','addressInfo'
            oriPersonalInfo: {},
            personalInfo: {},
            addressInfo: {},
            mobileCountryCodeOptions: [],
            countryCodeOptions: [],
            deliveryAddress: '',
            isShowAddressEditPanel: false,
            kycStatus: {},
            idTypeList: [
                {
                    value: 0,
                    label: 'passport',
                },
            ],
        }
    },
    methods: {
        initData() {
            this.getNationalOptions()


            this.$pu.showLoading()
            getUserKycDetails().then(res => {
                this.$pu.hideLoading()
                if (res.code === 200) {
                    this.kycStatus = res.data
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })


            // this.$pu.showLoading()
            // getPersonalInfo().then(res => {
            //     this.$pu.hideLoading()
            //     if (res.code === 200) {
            //         this.oriPersonalInfo = Object.assign({}, res.data)
            //         this.personalInfo = Object.assign({}, res.data)
            //         this.addressInfo = Object.assign({}, res.data)
            //         this.assignAddress(this.personalInfo)
            //     } else {
            //         this.$message.error(res.msg)
            //     }
            // }).catch(error => {
            //     console.log(error)
            //     this.$pu.hideLoading()
            //     this.$message.error(error)
            // })

        },
        getNationalOptions() {
            this.mobileCountryCodeOptions = []
            this.countryCodeOptions = []
            getNational().then(res => {
                if (res.code === 200) {
                    this.mobileCountryCodeOptions = this.cloneArray(res.data)
                    this.countryCodeOptions = this.cloneArray(res.data)
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                console.log(error)
                this.$message.error(error)
            })

        },
        cloneArray(source) {
            let newArray = []
            for (let item in source) {
				if(source[item].phoneCode !== "1" && source[item].phoneCode !== '86' ){
                    newArray.push(source[item])
					// this.countryCodeOptions.push(item)
				}
                // newArray.push(source[item])
            }
            return newArray
        },
        assignAddress(info) {
            let a1 = [
                info.deliveryAddress,
                info.deliveryAddressSecond,
                info.deliveryCity,
                info.deliveryState,
                this.fetchCountryName(info.deliveryCountry),
                info.deliveryZipCode,
            ]
            this.deliveryAddress = a1.join(" ")
            let a2 = [
                info.billingAddress,
                info.billingAddressSecond,
                info.billingCity,
                info.billingState,
                this.fetchCountryName(info.billingCountry),
                info.billingZipCode,
            ]
            this.billingAddress = a1.join(" ")
        },
        fetchCountryName(countryCode) {
            for (let i in this.mobileCountryCodeOptions) {
                if (this.mobileCountryCodeOptions[i].alpha2 == countryCode) {
                    return this.mobileCountryCodeOptions[i].name
                }
            }
            return ''
        },
        updatePersonalInfo() {
            let missingParamName = ''
            if (!this.personalInfo.firstName || this.personalInfo.firstName.trim() == '') {
                missingParamName = 'First Name'
            } 
            // else if (!this.personalInfo.middleName || this.personalInfo.middleName.trim() == '') {
            //     missingParamName = 'Middle Name'
            // } 
            else if (!this.personalInfo.lastName || this.personalInfo.lastName.trim() == '') {
                missingParamName = 'Last Name'
            } else if (!this.personalInfo.phone || this.personalInfo.phone.trim() == '') {
                missingParamName = 'Phone'
            } else if (!this.personalInfo.phoneCountryCode || this.personalInfo.phoneCountryCode.trim() == '') {
                missingParamName = 'Phone Country Code'
            } else if (!this.personalInfo.dateOfBirth || this.personalInfo.dateOfBirth.trim() == '') {
                missingParamName = 'Date Of Birth'
            }
            if (missingParamName) {
                this.$message.error(missingParamName + ' cannot be empty!')
                return
            }
            const personalInfoParams = {
                "firstName": this.personalInfo.firstName,
                "middleName": this.personalInfo.middleName,
                "lastName": this.personalInfo.lastName,
                "phoneCountryCode": this.personalInfo.phoneCountryCode,
                "phone": this.personalInfo.phone,
                "idNumber": this.personalInfo.idNumber,
                "idType": this.personalInfo.idType,
                "dateOfBirth": this.personalInfo.dateOfBirth,
            }
            const postParams = Object.assign(this.oriPersonalInfo, personalInfoParams)
            // console.log("postParams: " + JSON.stringify(postParams,null,'\t'))
            this.$pu.showLoading()
            updatePersonalInfo(postParams).then(res => {
                this.$pu.hideLoading()
                if (res.code === 200) {
                    this.$message.success('Personal information updated successfully!');
                    setTimeout(() => {
                        this.reload()
                    }, 1500);
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })

        },
        updateAddressInfo() {
            let missingParamName = ''
            if (!this.addressInfo.deliveryCountry || this.addressInfo.deliveryCountry.trim() == '') {
                missingParamName = 'Delivery Country'
            } else if (!this.addressInfo.deliveryAddress || this.addressInfo.deliveryAddress.trim() == '') {
                missingParamName = 'Delivery Address 1'
            } else if (!this.addressInfo.deliveryAddressSecond || this.addressInfo.deliveryAddressSecond.trim() == '') {
                missingParamName = 'Delivery Address 2'
            } else if (!this.addressInfo.deliveryCity || this.addressInfo.deliveryCity.trim() == '') {
                missingParamName = 'Delivery City'
            } else if (!this.addressInfo.deliveryState || this.addressInfo.deliveryState.trim() == '') {
                missingParamName = 'Delivery State'
            } else if (!this.addressInfo.deliveryZipCode || this.addressInfo.deliveryZipCode.trim() == '') {
                missingParamName = 'Delivery Zip Code'
            } else if (!this.addressInfo.billingCountry || this.addressInfo.billingCountry.trim() == '') {
                missingParamName = 'Billing Country'
            } else if (!this.addressInfo.billingAddress || this.addressInfo.billingAddress.trim() == '') {
                missingParamName = 'Billing Address 1'
            } else if (!this.addressInfo.billingAddressSecond || this.addressInfo.billingAddressSecond.trim() == '') {
                missingParamName = 'Billing Address 2'
            } else if (!this.addressInfo.billingCity || this.addressInfo.billingCity.trim() == '') {
                missingParamName = 'Billing City'
            } else if (!this.addressInfo.billingState || this.addressInfo.billingState.trim() == '') {
                missingParamName = 'Billing State'
            } else if (!this.addressInfo.billingZipCode || this.addressInfo.billingZipCode.trim() == '') {
                missingParamName = 'Billing Zip Code'
            }
            if (missingParamName) {
                this.$message.error(missingParamName + ' cannot be empty!')
                return
            }
            const infoParams = {
                "deliveryCountry": this.addressInfo.deliveryCountry,
                "deliveryAddress": this.addressInfo.deliveryAddress,
                "deliveryAddressSecond": this.addressInfo.deliveryAddressSecond,
                "deliveryCity": this.addressInfo.deliveryCity,
                "deliveryState": this.addressInfo.deliveryState,
                "deliveryZipCode": this.addressInfo.deliveryZipCode,
                "billingCountry": this.addressInfo.billingCountry,
                "billingAddress": this.addressInfo.billingAddress,
                "billingAddressSecond": this.addressInfo.billingAddressSecond,
                "billingCity": this.addressInfo.billingCity,
                "billingState": this.addressInfo.billingState,
                "billingZipCode": this.addressInfo.billingZipCode
            }
            const postParams = Object.assign(this.oriPersonalInfo, infoParams)
            this.$pu.showLoading()
            updatePersonalInfo(postParams).then(res => {
                this.$pu.hideLoading()
                if (res.code === 200) {
                    this.$message.success('Address information updated successfully!');
                    setTimeout(() => {
                        this.reload()
                    }, 1500);
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })

        },
        kycInitiateClick() {
            let complianceStatus = this.kycStatus.complianceStatus
            let kycLink = this.kycStatus.kycLink
            let showIframe = this.kycStatus.showIframe
            if (complianceStatus == "ACTION_REQUIRED" || complianceStatus == "REJECTED") {
                this.$message.error("Your KYC was rejected, please contact your admin!");
            } else if (
                complianceStatus === "IN_PROGRESS" && showIframe && kycLink !== ""
            ) {
                window.open(kycLink, "_blank");
            } else {
                this.$message.error("Something went wrong, please try again later!");
            }
        },
        handlerTabClick(tabIns) {
            console.log("handlerTabClick: " + JSON.stringify(tabIns))
            if (!tabIns) {
                return
            }
            if ((tabIns.paneName == 'addressInfo' || tabIns.paneName == 'personalInfo')) {
                this.$pu.showLoading()
                getPersonalInfo().then(res => {
                    this.$pu.hideLoading()
                    if (res.code === 200) {
                        this.oriPersonalInfo = Object.assign({}, res.data)
                        this.personalInfo = Object.assign({}, res.data)
                        this.addressInfo = Object.assign({}, res.data)
                        this.assignAddress(this.personalInfo)
                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch(error => {
                    console.log(error)
                    this.$pu.hideLoading()
                    this.$message.error(error)
                })
            } else if (tabIns.paneName == 'kycInfo') {
                this.$pu.showLoading()
                getUserKycDetails().then(res => {
                    this.$pu.hideLoading()
                    if (res.code === 200) {
                        this.kycStatus = res.data
                    } else {
                        this.$message.error(res.msg)
                    }
                }).catch(error => {
                    console.log(error)
                    this.$pu.hideLoading()
                    this.$message.error(error)
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.css';

@import '@/assets/css/profile.css';

.default-input {
    .input {
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        background: #fff;
        border-radius: 10px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #1a1b26;
        border: none;
        width: 100%;
        padding: 0;
        border: 0;
        font-family: "GothamPro", sans-serif;
    }

    input {
        // background: #fff;
        // border-radius: 10px;
        // font-weight: 500;
        // font-size: 15px;
        // line-height: 20px;
        // color: #1a1b26;
        // border: none;
        // width: 100%;
        // // padding: 15px 20px;
        // border:0;
        // font-family: "GothamPro", sans-serif;
    }
}

:deep() {
    .el-select__wrapper {

        box-shadow: none;
    }

    .el-select__wrapper.is-hovering:not(.is-focused) {
        box-shadow: none;
    }

    .el-select__wrapper.is-focused {
        box-shadow: none;
    }

    .date-of-birth{
        .el-input__wrapper{
            box-shadow: none;
        }
        .el-input{
            box-shadow: none;
        }
        .el-input__prefix{
            display: none;
        }
        .el-input__inner{
            padding-left: 0;
        }
    }
    .profile-adress {
        .el-tabs {
            // display: -webkit-box;
            // display: -ms-flexbox;
            // display: flex;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            overflow-x: auto;
            margin-bottom: 33px;
            width: 100%;
            padding-bottom: 2px;
            position: relative;

            .el-tabs__item {
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                color: #acaec8;
                margin-right: 50px;
                padding-bottom: 10px;
                position: relative;
                z-index: 2;
                -webkit-transition: all 0.3s ease-in;
                transition: all 0.3s ease-in;
                cursor: pointer;
                white-space: nowrap;
            }

            .el-tabs__item.is-active {
                color: #1a1b26
            }

            .el-tabs__active-bar {
                background: #3246d3;
                height: 4px;
            }

            .el-tabs__nav-wrap:after {
                // background: #3246d3;
                // height: 4px;
            }
        }
    }
}

.el-select {

    background: #fff;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #1a1b26;
    border: none;
    width: 100%;
    padding: 8px 20px;
    border-radius: 10px;
    border: none;
    height: auto;
    font-family: "GothamPro", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #1a1b26;
    width: 100%;
}

.el-select__wrapper {
    align-items: center;
    background-color: var(--el-fill-color-blank);
    border-radius: var(--el-border-radius-base);
    /* box-shadow: 0 0 0 1px var(--el-border-color) inset; */
    box-shadow: none;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    /* gap: 6px; */
    /* line-height: 24px; */
    /* min-height: 32px; */
    /* padding: 4px 12px; */
    position: relative;
    text-align: left;
    transition: var(--el-transition-duration);
}

// .wrapper {
//     max-width: 1240px;
//     padding: 0 50px;
//     margin: 0 auto;
// }
@media (max-width: 768px) {
    .table-kyc {
        thead {
            display: none;
        }

        tbody {
            tr {
                width: 100%;
                display: flex;
                flex-direction: column;

                td {
                    text-align: right;

                    #documentType_action {
                        justify-content: flex-end;
                    }
                }

            }
        }
    }
}
</style>