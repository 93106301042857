<template>
	<el-dialog class="mobilPhone" style="border-radius: 20px;margin-top: 15vh;" width="600px" title="Add crypto wallet"
		:btnloading="btnloading" @onClose="onClose" :visible="visible" :closeBtn='false' :sureBtn='false'>
		<div class="change-form">
			<!-- <div class="change-form-title">{{translateTitle('选择加密货币')}}</div> -->
			<el-select class="custom-select" popper-class="custom-select" filterable clearable v-model="value"
				placeholder="Search tokens">
				<el-option v-for="(item, index) in option" :key="index" :value="item.coinLink">
					<!-- <div class="select-item" @click.stop="aaqq">
                   <img width="30" :src="item.coinLogo">
                   <span>{{ item.coinName }}</span>
                 </div> -->
					<div class="list">
						<div style="display: flex;align-items: center;"><img class="mr10" width="30"
								:src="item.coinLogo">{{ item.coinName }}
							<p>({{item.coinLink}})</p>
						</div>
						<div>
							<el-button class="danger-plain" v-if="item.userHas" type="danger"
								@click.stop="onDelete(item)" size="small" plain>Delete</el-button>
							<el-button class="primary-plain" v-else type="primary" size="small"
								@click.stop="onSure(item)" plain>Add</el-button>
						</div>
					</div>
				</el-option>
			</el-select>
			<!-- <div v-for="(item, index) in option" :key="index">
          <div class="list">
            <div><img width="30" :src="item.coinLogo">{{ item.coinName }}({{item.coinLink}})</div>
            <div>
              <el-button class="danger-plain" v-if="item.userHas" type="danger" size="small" plain>删除</el-button>
              <el-button class="primary-plain" v-else type="primary" size="small" @click="onSure(item)" plain>添加</el-button>
            </div>
          </div>
        </div> -->
		</div>
		<!-- <div class="mt20">
      <el-checkbox v-model="form.reciveCondition">
        <span class="checked-text">{{translateTitle('接受条款和条约')}}</span>
      </el-checkbox>
    </div> -->
	</el-dialog>
</template>

<script>
	import {
		coinNames,
		coinNotHasNames,
		coinLink,
		supportCoinNames
	} from "@/api/index.js"
	import {
		addCoinWallet,
		deleteWallet
	} from "@/api/wallet.js"
	import {
		translateTitle
	} from "@/utils/i18n";
	export default {
		name: "addMoneyDiaolg",
		model: {
			prop: "visible",
			event: "visibleEvent",
		},

		props: {
			// 显示/隐藏
			visible: {
				type: Boolean,
				default: false,
				checked: true,
			},
		},
		data() {
			return {
				option: [],
				value: "",
				choiceCoin: {
					coinName: '',
					coinLogo: "",
				},
				CoinLinkOption: [],
				form: {
					coinName: '',
					coinLink: '',
					reciveCondition: '',
				},
				rules: {
					coinName: [{
						required: true,
						message: 'Please select tokens',
						trigger: "change"
					}, ],
				},
				btnloading: false,
				globalLoading: null,
			};
		},
		created() {
			this.getCoinName()
		},
		methods: {
			aaqq() {
				console.log(3312123213)
			},
			async getCoinName() {
				let res = await supportCoinNames();
				this.option = res.data
			},
			changeCoinName(data) {
				let obj = this.option.find(item => item.coinName === data)

				this.choiceCoin.coinName = obj.coinName;
				this.choiceCoin.coinLogo = obj.coinLogo;
				this.getCoinLink(this.form.coinName)
			},
			async getCoinLink(val) {
				let res = await coinLink(val);
				this.CoinLinkOption = res.data
			},
			onSure(row) {
				let params = {
					coinName: row.coinName,
					coinLink: row.coinLink,
					reciveCondition: true,
				}
				// this.$refs[formName].validate((valid) => {
				//   if (valid) {
				this.globalLoading = this.$loading({
					lock: true,
					text: '',
					spinner: '',
					background: 'rgba(255, 255, 255, 0.8)'
				});
				// this.btnloading = true
				addCoinWallet(params).then(res => {
					if (res.code == 200) {
						this.$message.success('Successful');
						row.userHas = true
						this.$emit("visibleEvent", false);
					} else {
						this.$message.error(res.msg);
					}
				}).finally(() => {
					// this.btnloading = false
					this.globalLoading.close()
				})
				//   } else {
				//     return false;
				//   }
				// });
			},
			onDelete(item) {
				let params = {
					coinName: item.coinName,
					coinLink: item.coinLink,
					reciveCondition: true,
				}
				this.$confirm(
						"确认删除？", '', {
							confirmButtonText: "Confirm",
							cancelButtonText: "Cancel",
							customClass: 'custom-message',
						}
					)
					.then(async () => {
						// this.btnloading = true
						this.globalLoading = this.$loading({
							lock: true,
							text: '',
							spinner: '',
							background: 'rgba(255, 255, 255, 0.8)'
						});
						deleteWallet(params).then(res => {
							if (res.code == 200) {
								this.$message.success("Successful");
								item.userHas = false
								this.$emit("visibleEvent", false);
							} else {
								this.$message.error(res.msg);
							}
						}).finally(() => {
							// this.btnloading = false
							this.globalLoading.close()
						})
					})
					.catch(() => {});

			},
			// 取消
			onClose() {
				this.$emit("visibleEvent", false);
			},
		},
	};
</script>
<style lang="scss" scoped>
	@media (max-width: 480px) {
	  :deep(.el-dialog) {
		  width: 90% !important;
	  }
	}
</style>e
<style lang="scss" scoped>
	.cryptoAccountModal-bottom {
		background: #eff6ff;
		border-radius: 15px;
		padding: 30px 30px 15px;
		margin-top: 10px;
	}

	:deep(.el-select__wrapper) {
		height: 56px !important;
		line-height: 56px !important;
		border-color: transparent;
		border-radius: 8px;
	}

	.change-form {
		background: #eff6ff;
		padding: 30px;
		margin: 20px 0;
		border-radius: 10px;
	}

	.list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 18px;
		color: #31363E;
		border-bottom: 1px solid #E7EEF7;
	}

	.list img {
		vertical-align: middle;
		margin-right: 10px;
		margin-top: -5px;
	}

	.checked-text {
		font-size: 20px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: bold;
		color: #5ec7dd;
	}

	::v-deep {
		.el-checkbox {
			display: flex;
			align-items: center;
		}

		.el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #94f294 !important;
			border-color: #94f294 !important;
		}

		.el-checkbox__input.is-checked+.el-checkbox__label {
			color: #000;
		}

		.el-checkbox__inner:hover {
			border-color: #000;
		}

		.el-checkbox__inner:hover {
			border-color: #b9bfca;
		}

		.el-checkbox__input {
			width: 20px;
			height: 20px;
		}

		.el-checkbox__inner {
			width: 100%;
			height: 100%;
			text-align: center;
			line-height: 20px;
		}

		.el-checkbox__inner::after {
			box-sizing: content-box;
			content: "";
			border: 2px solid #fff;
			border-left: 0;
			border-top: 0;
			height: 10px;
			left: 7px;
			position: absolute;
			top: 2px;
			transform: rotate(45deg) scaleY(0);
			width: 3px;
			transition: transform 0.15s ease-in 0.05s;
			transform-origin: center;
		}
	}

	::v-deep {
		.el-select-dropdown__item {
			height: 60px !important;
			line-height: 60px !important;

			img {
				vertical-align: middle;
			}
		}
	}

	.custom-select .el-select-dropdown__item {
		height: 60px !important;
		line-height: 60px !important;
	}

	.custom-select .el-select-dropdown__item img {
		vertical-align: middle;
		margin-top: -3px;
	}

	.el-scrollbar.el-select-dropdown__item.list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 18px;
		color: #31363E;
	}

	.custom-select .el-select-dropdown__item .list .el-button {
		width: 65px;
	}

	.custom-select .el-select-dropdown__item:hover {
		background: #cfe5ee;
		border-radius: 10px;
	}

	.custom-select .el-select-dropdown__list {
		padding: 10px !important;
	}
</style>