import request from "@/utils/request";
export const openValid = (data) =>
  request({
    url: "/user/setting/openValid",
    method: "post",
    data,
  });

export const openGoogleAuth = (data) =>
  request({
    url: "/user/setting/bindGoogleAuth",
    method: "post",
    data,
  });
  export const getGoogleAuth = () =>
    request({
      url: "/user/setting/getGoogleAuth",
      method: "get"
    });