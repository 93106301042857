<template>
  <div>
    <router-view v-if="isRouterAlive" />
    <div id="holdon-overlay" style="background-color: white; opacity: 0.0806647;" v-if="is_loading">
      <div id="holdon-content-container">
        <div id="holdon-content">
          <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </div>
        <div id="holdon-message" style="color: black;">LOADING ... PLEASE WAIT</div>
      </div>
    </div>
  </div>
</template>

<script>
// // 解决ERROR ResizeObserver loop completed with undelivered notifications.
// //问题的
// const debounce = (fn, delay) => {
//   let timer = null;
//   return function () {
//     let context = this;
//     let args = arguments;
//     clearTimeout(timer);
//     timer = setTimeout(function () {
//       fn.apply(context, args);
//     }, delay);
//   };
// };
// // 解决ERROR ResizeObserver loop completed with undelivered notifications.
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//   constructor(callback) {
//     callback = debounce(callback, 16);
//     super(callback);
//   }
// };
import SidebarComp from '@/components/sidebar/sidebar.vue'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  components: {
    SidebarComp
  },

  created() {
  },
  mounted() {
	  const debounce = (fn, delay) => {
	  	  let timer = null;
	  	  return function () {
	  	    let context = this;
	  	    let args = arguments;
	  	    clearTimeout(timer);
	  	    timer = setTimeout(function () {
	  	      fn.apply(context, args);
	  	    }, delay);
	  	  };
	  	};
	  	// 解决ERROR ResizeObserver loop completed with undelivered notifications.
	  	const _ResizeObserver = window.ResizeObserver;
	  	window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	  	  constructor(callback) {
	  	    callback = debounce(callback, 16);
	  	    super(callback);
	  	  }
	  	};
  },
  watch: {
    // 监听路由对象中的 `$route` 属性
    // '$route'(to, from) {
    //   this.currentPath = to.path
    //   if (this.currentPath === '/login' || this.currentPath === 'registion') {
    //     this.isDashBoard = false
    //   } else {
    //     this.isDashBoard = true
    //   }
    //   console.log("this.isDashBoard: ", this.isDashBoard)
    // }
  },
  computed: {
    is_loading() {
      return this.$store.state.loading
    }
    // currentPath: {
    //   get() {
    //     console.log("this.$route.path: " + this.$route.path)
    //     return this.$route.path; // 获取当前路由路径
    //   }
    // }
  },
  data() {
    return {
      isRouterAlive: true,
      isDashBoard: false,
      currentPath: ''
    }
  },
  methods: {
    reload() {
      console.log('reload')
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
  }
}
</script>

<style lang="scss">
@import './assets/css/reset.css';
@import './assets/css/index.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #1b0f08;
}
</style>
