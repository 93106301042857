<template>
    <MainContentComp headerTitle="Recipients">
        <section class="settingsPage block">
            <div class="wrapper">
                <div class="tabs">
                    <div class="tab active">
                        Security Settings
                    </div>
                    <!-- <div class="tab">Notifications Settings</div>
                        <div class="tab">Documents</div> -->
                </div>
                <div class="container active">
                    <div class="security">
                        <form action="#">
                            <div class="security-item default-input">
                                <label>Email settings</label>
                                <div class="security-input noHidden">
                                    <p class="security-text">{{ emailAddress }}</p>
                                    <div class="verify">
                                        <span class="icon"><img :src="require('@/assets/img/settings/verify.svg')"
                                                alt=""></span>
                                        <span class="verify-text">
                                            Verified!
                                        </span>
                                    </div>
                                    <div style="width: 40px;height: 40px;margin-left: 20px;">

                                    </div>
                                    <!-- <a href="#" class="security-change modal-openButton" data-modal="changeEmail"> -->
                                    <!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.0504 3.00002L4.20878 10.2417C3.95045 10.5167 3.70045 11.0584 3.65045 11.4334L3.34211 14.1333C3.23378 15.1083 3.93378 15.775 4.90045 15.6084L7.58378 15.15C7.95878 15.0834 8.48378 14.8084 8.74211 14.525L15.5838 7.28335C16.7671 6.03335 17.3004 4.60835 15.4588 2.86668C13.6254 1.14168 12.2338 1.75002 11.0504 3.00002Z"
                                                stroke="#3246D3" stroke-width="1.25" stroke-miterlimit="10"
                                                stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path d="M9.9082 4.20898C10.2665 6.50898 12.1332 8.26732 14.4499 8.50065"
                                                stroke="#3246D3" stroke-width="1.25" stroke-miterlimit="10"
                                                stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path d="M2.5 18.334H17.5" stroke="#3246D3" stroke-width="1.25"
                                                stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round">
                                            </path>
                                        </svg> -->
                                    <!-- </a> -->
                                </div>
                            </div>
                            <!-- <div class="security-item default-input">
                                    <label>Language settings</label>
                                    <select class="default-select default-select__icons" name="123">
                                        <option value="english" data-image="resources/Onboarding/img/lang/en.svg">English (UK)</option>        
                                        <option value="canada" data-image="resources/Onboarding/img/lang/canada.svg">Canada</option>        
                                        <option value="estonia" data-image="resources/Onboarding/img/lang/estonia.svg">Estonia</option>        
                                        <option value="spain" data-image="resources/Onboarding/img/lang/spain.svg">Spain</option>        
                                        <option value="france" data-image="resources/Onboarding/img/lang/france.svg">France</option>        
                                        <option value="canada" data-image="resources/Onboarding/img/lang/canada.svg">Canada</option>        
                                        <option value="poland" data-image="resources/Onboarding/img/lang/poland.svg">Poland</option>        
                                        <option value="latvia" data-image="resources/Onboarding/img/lang/latvia.svg">Latvia</option>        
                                        <option value="ireland" data-image="resources/Onboarding/img/lang/ireland.svg">Ireland</option>        
                                    </select>
                                </div> -->
                            <!-- <div class="security-item default-input security-password oldPassword">
                                    <label>Change password</label>
                                    <div class="security-input">
                                        <img src="img/settings/password.svg" alt="">
                                        <div class="security-change">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.0504 3.00002L4.20878 10.2417C3.95045 10.5167 3.70045 11.0584 3.65045 11.4334L3.34211 14.1333C3.23378 15.1083 3.93378 15.775 4.90045 15.6084L7.58378 15.15C7.95878 15.0834 8.48378 14.8084 8.74211 14.525L15.5838 7.28335C16.7671 6.03335 17.3004 4.60835 15.4588 2.86668C13.6254 1.14168 12.2338 1.75002 11.0504 3.00002Z" stroke="#3246D3" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M9.9082 4.20898C10.2665 6.50898 12.1332 8.26732 14.4499 8.50065" stroke="#3246D3" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2.5 18.334H17.5" stroke="#3246D3" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div> -->
                            <div class="security-item default-input invalid security-password newPassword">
                                <label>New password</label>
                                <div class="security-input password-input">
                                    <input type="password" placeholder="Enter a new password">
                                    <div class="password-show">
                                        <svg class="show" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.6849 10.0034C12.6849 11.4884 11.4849 12.6884 9.99994 12.6884C8.51494 12.6884 7.31494 11.4884 7.31494 10.0034C7.31494 8.51836 8.51494 7.31836 9.99994 7.31836C11.4849 7.31836 12.6849 8.51836 12.6849 10.0034Z"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path
                                                d="M9.99988 15.8383C12.6474 15.8383 15.1149 14.3706 16.8324 11.8303C17.5074 10.8354 17.5074 9.16304 16.8324 8.16811C15.1149 5.62786 12.6474 4.16016 9.99988 4.16016C7.35238 4.16016 4.88488 5.62786 3.16738 8.16811C2.49238 9.16304 2.49238 10.8354 3.16738 11.8303C4.88488 14.3706 7.35238 15.8383 9.99988 15.8383Z"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                        <svg class="hide" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.8974 8.10586L8.10244 11.9009C7.61494 11.4134 7.31494 10.7459 7.31494 10.0034C7.31494 8.51836 8.51494 7.31836 9.99994 7.31836C10.7424 7.31836 11.4099 7.61836 11.8974 8.10586Z"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path
                                                d="M5.19988 14.0795C4.44988 13.4719 3.75988 12.7159 3.16738 11.8398C2.49238 10.8437 2.49238 9.16926 3.16738 8.17309C4.88488 5.62968 7.35238 4.16016 9.99988 4.16016C11.4103 4.16016 12.7773 4.57998 13.9999 5.35606"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path
                                                d="M16.0374 7.12695C16.3149 7.45194 16.5849 7.79813 16.8324 8.16551C17.5074 9.16168 17.5074 10.8361 16.8324 11.8323C15.1149 14.3757 12.6474 15.8452 9.99989 15.8452C9.402 15.8452 8.81042 15.768 8.23535 15.6214"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path d="M12.6326 10.5273C12.4376 11.5848 11.5751 12.4473 10.5176 12.6423"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path d="M8.14322 11.8594L2.66113 17.3415" stroke="#ACAEC8"
                                                stroke-width="1.15625" stroke-linecap="round" stroke-linejoin="round">
                                            </path>
                                            <path d="M17.339 2.66406L11.8569 8.14615" stroke="#ACAEC8"
                                                stroke-width="1.15625" stroke-linecap="round" stroke-linejoin="round">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <p class="message">Password must have at least 8 characters, 1 lowercase, 1 upper case,
                                    1 number and 1 special character. </p>
                            </div>
                            <div class="security-item default-input security-password newPassword">
                                <label>Re-enter new password</label>
                                <div class="security-input password-input">
                                    <input type="password" placeholder="Enter a new password">
                                    <div class="password-show">
                                        <svg class="show" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.6849 10.0034C12.6849 11.4884 11.4849 12.6884 9.99994 12.6884C8.51494 12.6884 7.31494 11.4884 7.31494 10.0034C7.31494 8.51836 8.51494 7.31836 9.99994 7.31836C11.4849 7.31836 12.6849 8.51836 12.6849 10.0034Z"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path
                                                d="M9.99988 15.8383C12.6474 15.8383 15.1149 14.3706 16.8324 11.8303C17.5074 10.8354 17.5074 9.16304 16.8324 8.16811C15.1149 5.62786 12.6474 4.16016 9.99988 4.16016C7.35238 4.16016 4.88488 5.62786 3.16738 8.16811C2.49238 9.16304 2.49238 10.8354 3.16738 11.8303C4.88488 14.3706 7.35238 15.8383 9.99988 15.8383Z"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                        <svg class="hide" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.8974 8.10586L8.10244 11.9009C7.61494 11.4134 7.31494 10.7459 7.31494 10.0034C7.31494 8.51836 8.51494 7.31836 9.99994 7.31836C10.7424 7.31836 11.4099 7.61836 11.8974 8.10586Z"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path
                                                d="M5.19988 14.0795C4.44988 13.4719 3.75988 12.7159 3.16738 11.8398C2.49238 10.8437 2.49238 9.16926 3.16738 8.17309C4.88488 5.62968 7.35238 4.16016 9.99988 4.16016C11.4103 4.16016 12.7773 4.57998 13.9999 5.35606"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path
                                                d="M16.0374 7.12695C16.3149 7.45194 16.5849 7.79813 16.8324 8.16551C17.5074 9.16168 17.5074 10.8361 16.8324 11.8323C15.1149 14.3757 12.6474 15.8452 9.99989 15.8452C9.402 15.8452 8.81042 15.768 8.23535 15.6214"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path d="M12.6326 10.5273C12.4376 11.5848 11.5751 12.4473 10.5176 12.6423"
                                                stroke="#ACAEC8" stroke-width="1.15625" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                            <path d="M8.14322 11.8594L2.66113 17.3415" stroke="#ACAEC8"
                                                stroke-width="1.15625" stroke-linecap="round" stroke-linejoin="round">
                                            </path>
                                            <path d="M17.339 2.66406L11.8569 8.14615" stroke="#ACAEC8"
                                                stroke-width="1.15625" stroke-linecap="round" stroke-linejoin="round">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <!-- <p class="message">Password confirmation does not match.</p> -->

                            </div>
                            <!-- for disable -->

                            <div class="security-authentication" id="faN">
                                <p>Two-Factor authentication (2FA)</p>
                                <div class="authentication-checkbox">
                                    <el-switch v-model="isEnable2FA"
                                        style="--el-switch-on-color: #3246d3; --el-switch-off-color: #c5d9f2;width: 34px;height: 20px;"
                                        @change="onEnableChanged" :before-change="beforeEnableChanged"></el-switch>
                                    <!-- <input type="checkbox" id="Two-Factor">
                                    <label class="authentication-checkbox__label" for="Two-Factor"></label> -->
                                </div>
                            </div>
                            <!-- for enable -->
                            <!--                                 <div class="security-authentication" id="faY">
                                    <p>Two-Factor authentication (2FA)</p>                                   
									<div class="authenticatio-checkbox">
                                        <input type="checkbox" id="Two-Factor" onclick="disableFA()">
                                        <label class="authenticatio-checkbox__label modal-openButton" data-modal="cryptoAccountModal" for="Two-Factor"></label>
                                    </div>                                    
                                </div>
 -->
                            <div class="buttons">
                                <button type="submit" class="button">Save Changes</button>
                                <a href="#" class="button close">Close</a>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="container">
                    <form action="#" class="notifications">
                        <div class="notifications-top">
                            <h3 class="notifications-title">Notifications</h3>
                            <div class="notifications-checks">
                                <div class="notifications-check">
                                    <input type="checkbox" id="ex-1-1" readonly="" checked="">
                                    <label class="notifications-check__container" for="ex-1-1">
                                        <span class="notifications-check__block"></span>
                                        <p class="notifications-check__description">Push</p>
                                    </label>
                                </div>
                                <div class="notifications-check">
                                    <input type="checkbox" id="ex-1-2" readonly="" checked="">
                                    <label class="notifications-check__container" for="ex-1-2">
                                        <span class="notifications-check__block"></span>
                                        <p class="notifications-check__description">Email</p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="notifications-items">
                            <div class="notifications-item">
                                <div class="notifications-info">
                                    <div class="notifications-title">Your transfers and balances</div>
                                    <div class="notifications-text">Notifications about where your money is.</div>
                                </div>
                                <div class="notifications-checks">
                                    <div class="notifications-check">
                                        <input type="checkbox" id="ex-2-1">
                                        <label class="notifications-check__container" for="ex-2-1">
                                            <span class="notifications-check__block"></span>
                                        </label>
                                    </div>
                                    <div class="notifications-check">
                                        <input type="checkbox" id="ex-2-2">
                                        <label class="notifications-check__container" for="ex-2-2">
                                            <span class="notifications-check__block"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="notifications-item">
                                <div class="notifications-info">
                                    <div class="notifications-title">Your debit card</div>
                                    <div class="notifications-text">Notifications about your card transactions.</div>
                                </div>
                                <div class="notifications-checks">
                                    <div class="notifications-check">
                                        <input type="checkbox" id="ex-3-1">
                                        <label class="notifications-check__container" for="ex-3-1">
                                            <span class="notifications-check__block"></span>
                                        </label>
                                    </div>
                                    <div class="notifications-check">
                                        <input type="checkbox" id="ex-3-2">
                                        <label class="notifications-check__container" for="ex-3-2">
                                            <span class="notifications-check__block"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="notifications-item">
                                <div class="notifications-info">
                                    <div class="notifications-title">Currencies and features</div>
                                    <div class="notifications-text">News about our latest and greatest work. Plus tips
                                        on using.</div>
                                </div>
                                <div class="notifications-checks">
                                    <div class="notifications-check">
                                        <input type="checkbox" id="ex-4-1">
                                        <label class="notifications-check__container" for="ex-4-1">
                                            <span class="notifications-check__block"></span>
                                        </label>
                                    </div>
                                    <div class="notifications-check">
                                        <input type="checkbox" id="ex-4-2">
                                        <label class="notifications-check__container" for="ex-4-2">
                                            <span class="notifications-check__block"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="notifications-item">
                                <div class="notifications-info">
                                    <div class="notifications-title">Interviews, reviews and surveys</div>
                                    <div class="notifications-text">Invites to test new products and share your
                                        thoughts.</div>
                                </div>
                                <div class="notifications-checks">
                                    <div class="notifications-check">
                                        <input type="checkbox" id="ex-5-1">
                                        <label class="notifications-check__container" for="ex-5-1">
                                            <span class="notifications-check__block"></span>
                                        </label>
                                    </div>
                                    <div class="notifications-check">
                                        <input type="checkbox" id="ex-5-2">
                                        <label class="notifications-check__container" for="ex-5-2">
                                            <span class="notifications-check__block"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="notifications-item">
                                <div class="notifications-info">
                                    <div class="notifications-title">Our campaigns</div>
                                    <div class="notifications-text">Updates about causes we care about. Chances to get
                                        involved.</div>
                                </div>
                                <div class="notifications-checks">
                                    <div class="notifications-check">
                                        <input type="checkbox" id="ex-6-1">
                                        <label class="notifications-check__container" for="ex-6-1">
                                            <span class="notifications-check__block"></span>
                                        </label>
                                    </div>
                                    <div class="notifications-check">
                                        <input type="checkbox" id="ex-6-2">
                                        <label class="notifications-check__container" for="ex-6-2">
                                            <span class="notifications-check__block"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="notifications-description">There are some things that we will always need to tell you
                            about - like changes to our T&amp;Cs.</p>
                        <button class="button" type="submit">Save Changes</button>
                    </form>
                </div>
                <div class="container">
                    <div class="documents">
                        <div class="documents-items">
                            <div class="documents-item">
                                <div class="documents-top">
                                    <div class="documents-title">Requested</div>
                                </div>
                                <div class="documents-block">
                                    <div class="documents-block__title">Proof of Address/Alternative Government ID</div>
                                    <div class="documents-block__text">Proof of address: Please provide a recent 90 days
                                        copy of proof of address confirming your name and address from your profile or
                                        an additional valid Government issued ID.</div>
                                    <div class="documents-block__bottom">
                                        <div class="documents-block__date">September 20, 2022 / 19:30</div>
                                        <div class="documents-block__requested">Requested</div>
                                    </div>
                                </div>
                            </div>
                            <div class="documents-item withoutTitle">
                                <div class="documents-block">
                                    <div class="documents-block__title">Government - issued ID Back</div>
                                    <div class="documents-block__text">Please provide a copy of the back side of your
                                        valid Government issued ID: Driver License or National ID card</div>
                                    <div class="documents-block__bottom">
                                        <div class="documents-block__date">September 20, 2022 / 19:30</div>
                                        <div class="documents-block__requested">Requested</div>
                                    </div>
                                </div>
                            </div>

                            <div class="documents-item">
                                <div class="documents-top">
                                    <div class="documents-title">Upload document here</div>
                                    <div class="documents-info"><img
                                            :src="require('@/assets/img/accountSteps/info.svg')" alt=""></div>
                                </div>
                                <div class="upload">
                                    <label class="upload-main">
                                        <input type="file" imultiple="" accept="image/*">
                                        <div class="upload-icon">
                                            <img :src="require('@/assets/img/settings/documents.svg')" alt="">
                                        </div>
                                        <div class="upload-title">
                                            Drag &amp; Drop your file(s) here or <span class="blue"> Browse to
                                                upload</span>
                                        </div>
                                        <div class="upload-text">Only JPEG, PNG and PDF files with max size of 15MB
                                        </div>
                                    </label>
                                    <div class="upload-gallery"></div>
                                    <div class="upload-progress">
                                        <div class="upload-progress__block"></div>
                                        <span class="upload-progress__text">Uploading...</span>
                                        <div class="upload-progress__percent">
                                            <span class="val">0</span>
                                            %
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <a href="#" class="documents-terms">Terms and Conditions</a>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal changeInfo" :class="isShowDisable2FAPanel ? 'active' : ''" data-modal="disable2FA">
            <div class="modal-block">
                <div class="modal-top">
                    <h2 class="modal-title">Disable 2FA</h2>
                    <div class="modal-close buttonClose testdis" @click="isShowDisable2FAPanel = false">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 11L11 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
                            <path d="M11 11L1 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
                        </svg>
                    </div>
                </div>
                <div class="message">
                    <span class="icon">
                        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 9V18.8949" stroke="#FF5656" stroke-width="2" stroke-linecap="round"></path>
                            <path d="M15 22.4727L15 23.304" stroke="#FF5656" stroke-width="2" stroke-linecap="round">
                            </path>
                            <path
                                d="M18.4966 4.23481C16.9726 1.49157 13.0274 1.49157 11.5034 4.2348L1.60191 22.0574C0.120734 24.7236 2.04861 28 5.09854 28H24.9015C27.9514 28 29.8793 24.7236 28.3981 22.0574L18.4966 4.23481Z"
                                stroke="#FF5656" stroke-width="2"></path>
                        </svg>
                    </span>
                    <p>Are you sure you want to disable two-step authentication for your account? This will lower the
                        security level of your data.</p>
                </div>
                <div class="buttons">
                    <a href="#" class="button buttonClose" @click="disableFA">Disable 2fA</a>
                </div>
                <p class="description">You can re-enable 2FA at any time</p>
            </div>
        </div>
        <div class="modal changeInfo" :class="isShowEnable2FAPanel ? 'active' : ''" data-modal="enable2FA">
            <div class="modal-block">
                <div class="modal-top">
                    <h2 class="modal-title">Enable 2FA</h2>
                    <div class="modal-close buttonClose testen" @click="isShowEnable2FAPanel = false">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 11L11 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
                            <path d="M11 11L1 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
                        </svg>
                    </div>
                </div>
                <div class="message">
                    <span class="icon">
                        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 9V18.8949" stroke="#FF5656" stroke-width="2" stroke-linecap="round"></path>
                            <path d="M15 22.4727L15 23.304" stroke="#FF5656" stroke-width="2" stroke-linecap="round">
                            </path>
                            <path
                                d="M18.4966 4.23481C16.9726 1.49157 13.0274 1.49157 11.5034 4.2348L1.60191 22.0574C0.120734 24.7236 2.04861 28 5.09854 28H24.9015C27.9514 28 29.8793 24.7236 28.3981 22.0574L18.4966 4.23481Z"
                                stroke="#FF5656" stroke-width="2"></path>
                        </svg>
                    </span>
                    <p>On enabling 2FA, will ask for additional login code during log-in.</p>
                </div>
                <h4 style="margin-top: 5px;font-weight: 500;">Set up virtual 2FA device</h4>
                <div class="block">
                    <p class="block-text">1. Install an authenticator app on your mobile device or computer</p>
                    <p class="block-text">2. Use your virtual 2FA app and your device's camera to scan the QR code</p>
                    <div class="cryptoAccountModal-bottom" style="margin-top: 1px!important;">

                        <canvas ref="qrcodeCanvas" size="260" style="margin:0 auto;margin-left: 35px;"></canvas>
                        <!-- <div class="qr" id="qrShowDiv" style="margin-left: 35px;">
                        </div> -->
                    </div>
                    <p class="block-text">Alternatively, you can type the below secret key</p>
                    <div class="default-input">
                        <input type="text" readonly="" id="secretKeyValue" v-model="secretKeyValue">
                    </div>
                    <p class="block-text" style="margin-top: 4px;">3.Type 2FA code below, shown on your virtual 2FA app
                    </p>
                    <div class="default-input">
                        <input type="number" placeholder="Enter 6-digit code" maxlength="6" v-model="securityCode"
                            id="sixDigitCode">
                    </div>
                    <button type="button" class="button" @click="enable2FA">Enable 2FA</button>
                    <p class="description">If you do not receive the 6-digit code within 30 seconds, you can <a
                            @click="reloadPage">try it again</a></p>
                </div>
            </div>
        </div>
    </MainContentComp>
</template>

<script>
import MainContentComp from '@/components/section/mainComp.vue'
import { openValid, openGoogleAuth, getGoogleAuth } from '@/api/setting'
import { getUserInfo } from '@/api/user'
import QRCode from 'qrcode'

export default {
    name: 'FeeTable',
    inject: ['reload'],
    components: {
        MainContentComp
    },
    props: {
        msg: String
    },
    data() {
        return {
            emailAddress:'',
            isEnable2FA: false,
            isShowEnable2FAPanel: false,
            isShowDisable2FAPanel: false,
            secretKeyValue: '',
            securityCode: '',
            qrCodeContent: ''
        }
    },
    watch: {
        // propName: {
        //     handler(newName, oldName) {
        //     },
        //     immediate: true, //首次绑定的时候，是否执行 handler
        // },
    },
    computed: {
        // is_loading() {
        //     return this.$store.state.loading
        // }
    },
    created() { },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            this.$pu.showLoading()
            // Promise.all().then(results=>{

            // })
            getUserInfo().then(res => {
                this.$pu.hideLoading()
                if (res.code === 200) {
                    this.isEnable2FA = res.data.openGoogleValid
                    this.emailAddress = res.data.email
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        },
        generateQRCode() {
            const canvas = this.$refs.qrcodeCanvas
            QRCode.toCanvas(canvas, this.qrCodeContent, {
                width: 260,
                margin: 1,
                color: {
                    dark: '#5868bf',
                    light: 'ffffff'
                }
            }, error => {
                if (error) console.error(error)
            })
        },
        reloadPage() {
            this.reload()
        },
        toggleEnable2FAPanel(val) {
            this.isShowEnable2FAPanel = val
        },
        beforeEnableChanged(val) {
            if (this.isEnable2FA) {
                this.isShowDisable2FAPanel = true
            } else {
                this.showEnable2FAPanel()
            }
            return false
        },
        onEnableChanged(val) {
            // if (val) {
            //     this.isShowEnable2FAPanel = true
            // }
        },
        // showDisable2FAPanel(){
        //         this.isShowDisable2FAPanel = true
        // },
        showEnable2FAPanel() {
            this.$pu.showLoading()
            getGoogleAuth().then(res => {
                if (res.code === 200) {
                    this.secretKeyValue = res.data.secret
                    this.qrCodeContent = res.data.googleAuthQrcodeText
                    this.$pu.hideLoading()
                    this.isShowEnable2FAPanel = true
                    this.generateQRCode()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        },
        enable2FA() {
            this.$pu.showLoading()
            const params = {
                securityCode: this.securityCode
            }
            openGoogleAuth(params).then(res => {
                this.$pu.hideLoading()
                if (res.code === 200) {
                    this.isEnable2FA = true
                    this.$message.success('2FA enabled successfully!')
                    this.isShowEnable2FAPanel = false
                    let _this = this
                    setTimeout(() => {
                        _this.reload()
                    }, 1500);
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        },
        disableFA() {
            this.$pu.showLoading()
            const params = {
                "openGoogleValid": false
            }
            openValid(params).then(res => {
                this.$pu.hideLoading()
                if (res.code === 200) {
                    this.$message.success('2FA disabled successfully!')
                    this.isEnable2FA = false
                    this.isShowDisable2FAPanel = false
                    let _this = this
                    setTimeout(() => {
                        _this.reload()
                    }, 1500);
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.css';
@import '@/assets/css/cryptoAccount.css';

.settingsPage .security-authentication {
    align-items: center
}

.authentication-checkbox {}

.cryptoAccountModal-bottom {
    display: flex;
    align-items: center;
    justify-items: center;
}
</style>