<template>
	<MainContentComp headerTitle="Account">
		<section class="account__content block block__active account-steps" v-if="isShowStepContent">
			<div class="wrapper">
				<div class="steps">
					<div class="step" :class="getStepClass(1)">
						<div class="step-item">
							<div class="step-block">

							</div>
							<p class="step-title">
								Profile <br> Completed
							</p>
						</div>
					</div>

					<div class="step " :class="getStepClass(2)">
						<div class="step-item">
							<div class="step-block">

							</div>
							<p class="step-title">
								Complete <br> Your KYC
							</p>
						</div>
					</div>

					<div class="step" :class="getStepClass(3)">
						<div class="step-item">
							<div class="step-block">

							</div>
							<p class="step-title">
								Add Your <br> Account
							</p>
						</div>
					</div>

					<div class="step" :class="getStepClass(4)">
						<div class="step-item">
							<div class="step-block">

							</div>
							<p class="step-title">
								Start <br> Transacting
							</p>
						</div>
					</div>
				</div>
				<div class="kyc" v-if="currentStep == 2">
					<div class="kyc-icon">
						<img src="img/accountSteps/KYC.svg" alt="">
					</div>
					<h3 class="kyc-title">Complete Your KYC</h3>
					<!-- <p class="kyc-text">Secondary text</p> -->
					<a class="kyc-button" @click="$router.push('/profile')">
						Upload KYC Details</a>
				</div>

				<div class="addAccount" v-if="currentStep == 3">
					<div class="error-messageBlock displayNone">
						<span class="icon"><img :src="require('@/assets/img/icons/error.svg')" alt=""></span>
						<p class="text">You have reached the maximum personal Checking Account limit!</p>
					</div>
					<div class="wrapper">
						<h2 class="content__title">Add account</h2>
						<div class="addAccount-block">
							<form action="#">
								<div class="default-input item">
									<label>Label</label>
									<input id="accountLabel" type="text" v-model="accountLabel">
								</div>
								<div class="default-input item" data-select2-id="4">
									<label>Type</label>
									<select class="default-select select2-hidden-accessible"
										data-placeholders="Choose type" name="gender" id="accountType"
										data-select2-id="accountType" tabindex="-1" aria-hidden="true">
										<option data-select2-id="2"></option>
										<option value="personalChecking" data-select2-id="5">Personal account</option>
									</select><span
										class="select2 select2-container select2-container--default select2-container--below"
										dir="ltr" data-select2-id="1" style="width: 126px;"><span
											class="selection"><span class="select2-selection select2-selection--single"
												role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0"
												aria-disabled="false"
												aria-labelledby="select2-accountType-container"><span
													class="select2-selection__rendered"
													id="select2-accountType-container" role="textbox"
													aria-readonly="true" title="Personal account">Personal
													account</span><span class="select2-selection__arrow"
													role="presentation"><b
														role="presentation"></b></span></span></span><span
											class="dropdown-wrapper" aria-hidden="true"></span></span>
								</div>
								<div class="addAccount-check default-check">
									<input id="styled-checkbox-1" type="checkbox" v-model="isChecked">
									<label for="styled-checkbox-1">Accept <a target="_blank"
											href="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/Client-Config/T%26C+MM/MIMT%26Cs2021.pdf">Terms
											&amp; conditions</a></label>
								</div>
								<div class="addAccount-buttons">
									<div class="addAccount-button">
										<a href="/accountDashboard" class="close">Close</a>
									</div>
									<div class="addAccount-button">
										<a class="close" @click="createAccount">Add</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div class="kyc" v-if="currentStep == 4">
					<div class="kyc-icon">
						<img src="img/accountSteps/KYC.svg" alt="">
					</div>
					<h3 class="kyc-title">Complete</h3>
					<!-- <p class="kyc-text">Secondary text</p> -->
					<a class="kyc-button" @click="reload">
						Start Transacting
					</a>
				</div>
			</div>
		</section>
		<section class="account__content block block__active account-steps" v-if="!isShowStepContent">
			<div class="wrapper">
			<div class="account__content-top">
				<h2 class="content__title">Account Balance</h2>
				<div class="account__tabs">
					<div class="account__tab account__tab-active" @click="listShow">
						<a href="#"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
								<defs>
									<clipPath id="stfoa">
										<path fill="#fff"
											d="M0 1a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm0 7a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm0 7a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1z">
										</path>
									</clipPath>
								</defs>
								<g>
									<g>
										<path class="tab" fill="none" stroke="#1a1b26" stroke-miterlimit="20"
											stroke-width="4"
											d="M0 1a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm0 7a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm0 7a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1z"
											clip-path="url(&quot;#stfoa&quot;)">
										</path>
									</g>
								</g>
							</svg>
						</a>
					</div>
					<div class="account__tab account__tab-list" @click="cardShow">
						<a href="#"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
								<defs>
									<clipPath id="4p2ba">
										<path fill="#fff"
											d="M0 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zM0 8a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zM0 15a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1z">
										</path>
									</clipPath>
								</defs>
								<g>
									<g>
										<path class="tab" fill="none" stroke="#acaec8" stroke-miterlimit="20"
											stroke-width="4"
											d="M0 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zM0 8a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zM0 15a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1z"
											clip-path="url(&quot;#4p2ba&quot;)">
										</path>
									</g>
								</g>
							</svg>
						</a>
					</div>
				</div>
			</div>
			<div class="account__counts" v-if="list">
				<div class="account__count active" id="account0" v-for="(item, index) in globalAccountList"
					:key="index">
					<div class="top">
						<div class="info">
							<div class="icon">
								<img src="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/flags/flag/USD_svg.svg"
									alt="">
							</div>
							<p class="balance-text activeCard" id="accountBalanceGrid">$ 0.00</p>
							<div class="title">{{ item.currency }}</div>
							<div class="arrow noCard" @click="isShowClick(item, index)">
								<img v-if="item.show" src="../assets/img/up.png" style="width: 10px;" alt="">
								<img v-else src="../assets/img/down.png" style="width: 10px;" alt="">
							</div>
						</div>
						<div class="status desktop noCard">
							<p class="subtitle">Account Type</p>
						</div>
						<div class="balance desktop noCard">
							<p class="subtitle">Balance</p>
						</div>

					</div>
					<div class="bottom" v-if="item.show">
						<div class="arrow activeCard">
							<svg width="14" height="8" viewBox="0 0 14 8" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M1 1L5.58579 5.58579C6.36684 6.36684 7.63317 6.36683 8.41421 5.58579L13 1"
									stroke="#1A1B26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
								</path>
							</svg>
						</div>
						<div class="info" title="Account Number">
							<a class="number" id="accountNumber" @click="toPath(item)">{{ item.accountNumber }}</a>
						</div>
						<div class="status desktop">
							<p class="status-text" id="accountStatusCardView">{{ item.type }}</p>
						</div>
						<div class="balance desktop noCard">
							<p class="balance-text" id="accountBalanceList1">${{ item.availableBalance }}</p>
						</div>
						<div class="status mobile noCard">
							<div class="subtitle">Account Type</div>
							<p class="status-text" id="accountStatusListView">{{ item.type }}</p>
						</div>
						<div class="status mobile noCard">
							<div class="subtitle">Balance</div>
							<p class="balance-text" id="accountBalanceList2">
								${{ item.availableBalance }}
							</p>
						</div>

					</div>
				</div>
			</div>
			<div v-if="card">
				<div class="account__counts accont__items-cards">
					<div class="account__count active" v-for="(item, index) in globalAccountList" :key="index">
						<div class="top">
							<div class="info">
								<div class="icon">
									<img src="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/flags/flag/USD_svg.svg"
										alt="">
								</div>
								<p class="balance-text activeCard" id="accountBalanceGrid">
									${{ item.availableBalance }}</p>
								<div class="title">{{ item.currency }}</div>
								<div class="arrow noCard">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M12.2929 7.87871C12.6834 7.48818 13.3166 7.48818 13.7071 7.87871C14.0976 8.26923 14.0976 8.9024 13.7071 9.29292L10 13L6.29289 9.29292C5.90237 8.9024 5.90237 8.26923 6.29289 7.87871C6.68342 7.48818 7.31658 7.48818 7.70711 7.87871L10 10.1716L12.2929 7.87871Z"
											fill="#1A1B26">
										</path>
									</svg>
								</div>
							</div>
							<div class="status desktop noCard">
								<p class="subtitle">Account Type</p>
							</div>
							<div class="balance desktop noCard">
								<p class="subtitle">Balance</p>
							</div>

						</div>
						<div class="activeCard" @click="isShowClick(item, index)">
							<img v-if="item.show" src="../assets/img/up.png" style="width: 20px;" alt="">
							<img v-else src="../assets/img/down.png" style="width: 20px;" alt="">
						</div>
						<div class="bottom" v-if="item.show">
							<div class="info" title="Account Number">
								<a class="number" id="accountNumber" @click="toPath(item)">{{ item.accountNumber
									}}</a>
							</div>
							<div class="status desktop">
								<p class="status-text">{{ item.type }}</p>
							</div>
							<div class="balance desktop noCard">
								<p class="balance-text">${{ item.availableBalance }}</p>
							</div>
							<div class="status mobile noCard">
								<div class="subtitle">Account Type</div>
								<p class="status-text">{{ item.type }}</p>
							</div>
							<div class="status mobile noCard">
								<div class="subtitle">Balance</div>
								<p class="balance-text">
									${{ item.availableBalance }}
								</p>
							</div>

						</div>
					</div>
				</div>
			</div>
			</div>
		</section>
	</MainContentComp>
</template>

<script>
import {
	globalAccount
} from '@/api/transaction'
import MainContentComp from '@/components/section/mainComp.vue'
import { addAccount } from '@/api/bankAccount'
import { getUserKycDetails } from '@/api/kyc'
export default {
	inject: ['reload'],
	name: 'FeeTable',
	components: {
		MainContentComp
	},
	props: {
		msg: String
	},
	data() {
		return {
			isShowStepContent: true,
			currentStep: 2,
			globalAccountList: [],
			isShow: true,
			list: true,
			card: false,
			isChecked: false,
			accountLabel: ''
		}
	},
	mounted() {
		// this.getGlobalAccount()
		this.initData()
	},
	methods: {
		initData() {
			this.isShowStepContent = false
			this.$pu.showLoading()
			Promise.all([
				getUserKycDetails(),
				globalAccount()
			]).then(results => {
				this.$pu.hideLoading()

				let globalAccountRes = results[1]
				if (globalAccountRes.code == 200) {
					// globalAccountRes.data = []
					if (globalAccountRes.data.length > 0) {
						this.isShowStepContent = false
						this.globalAccountList = globalAccountRes.data.map((item) => {
							item.show = true;
							item.visible = false;
							return item;
						});
						this.globalAccountList = globalAccountRes.data
						return
					}
				} else {
					this.$message.error(globalAccountRes.msg)
				}


				let kycRes = results[0]
				if (kycRes.code === 200) {
					let kycStatus = kycRes.data
					if (kycStatus.state == 0 || kycStatus.state == 1) {
						this.isShowStepContent = true
						this.currentStep = 2
						return
					}
				} else {
					this.$message.error(kycRes.msg)
				}

				
				if (globalAccountRes.code == 200) {
					if (globalAccountRes.data.length == 0) {
						this.isShowStepContent = true
						this.currentStep = 3
						return
					}
				} else {
					this.$message.error(globalAccountRes.msg)
				}
			})
		},
		getGlobalAccount() {
			this.$pu.showLoading()
			globalAccount().then(res => {
				this.$pu.hideLoading()
				if (res.code == 200) {
					this.globalAccountList = res.data.map((item) => {
						item.show = true;
						item.visible = false;
						return item;
					});
					this.globalAccountList = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		isShowClick(item, index) {
			let coinListPrxy = JSON.parse(JSON.stringify(item))
			this.globalAccountList.forEach(res => {
				if (res.accountNumber == item.accountNumber) {
					res.show = !res.show;
				} else {
					res.show = false;
				}
			})
			item.visible = true;
		},
		toPath(item) {
			let list = JSON.parse(JSON.stringify(item))
			this.$router.push({
				path: '/accountDetails',
				query: list
			})
		},
		listShow() {
			this.list = true
			this.card = false
		},
		cardShow() {
			this.card = true
			this.list = false
		},
		getStepClass(step) {
			if (this.currentStep == step) {
				return 'active'
			} else if (this.currentStep > step) {
				return 'done'
			} else {
				return ''
			}
		},

		createAccount() {
			if (this.accountLabel.trim() == "") {
				this.$message.error('Label is Required!');
				return;
			}
			if (!this.validateAlphaNumeric(this.accountLabel)) {
				this.$message.error('label format is wrong! Please enter proper label (e.g: TestCrypto)');
				return;
			}
			if (this.isChecked != true) {
				this.$message.error('Please accept terms of use!');
				return
			}
			this.$pu.showLoading()
			const params = {
				"label": this.accountLabel
			}
			addAccount(params).then(res => {
				this.$pu.hideLoading()
				if (res.code === 200) {
					this.$message.success('Account created successfully!');
					let _this = this

					_this.currentStep = 4
					setTimeout(function () {
						_this.reload()
					}, 3000);
				} else {
					this.$message.error(res.msg)
				}
			}).catch(error => {
				this.$pu.hideLoading()
				this.$message.error(error)
			})
		},
		validateAlphaNumeric(data) {
			let filter = /^[a-zA-Z0-9_]*$/;
			if (filter.test(data)) {
				return true;
			} else {
				return false;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.css';
@import '@/assets/css/accountSteps.css';


.default-check label {
	position: relative;
	cursor: pointer;
	padding: 0;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	color: #acaec8;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.default-check label {
	position: relative;
	cursor: pointer;
	padding: 0;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: -0.5px;
	color: #eda858;
	display: flex;
	align-items: center;
}

.default-check label a {
	color: #3246d3;
	display: inline-block;
	margin: 0 4px;
}
</style>