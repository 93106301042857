import { createApp } from 'vue'
import App from './App.vue'

import './assets/css/select2.min.css'
const app = createApp(App);
//vuex配置
import store from '@/store/index'
app.use(store);

//vue-router配置
import router from './router'
app.use(router);

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPlus)


//通用工具配置
import pageUtils from '@/utils/pageUtils'
app.config.globalProperties.$pu = pageUtils;
app.mount('#app')