<template>
	<div class="dashboard signUpPage" style="background-color: #FFFFFF;">
		<main>
			<section class="signUp" style="background-color: #FFFFFF;">
				<div class="top" style="background-color: transparent;">
					<div class="wrapper" style="background-color: transparent;">
						<a href="#" class="logo"><img
								src="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/logo.svg" alt=""></a>
						<div class="top-right">
							<p class="top-text" style="color: #165ba4;">Don't have an account?</p>
							<a href="/signup" class="signIn" style="color: #165ba4;">
								<span class="icon">
									<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M11.25 16.5H6.75002C5.83502 16.5 5.05502 16.41 4.39502 16.215C4.56002 14.265 6.56252 12.7275 9.00002 12.7275C11.4375 12.7275 13.44 14.265 13.605 16.215C12.945 16.41 12.165 16.5 11.25 16.5Z"
											stroke="white" stroke-width="1.125" stroke-linecap="round"
											stroke-linejoin="round"></path>
										<path class="fill"
											d="M4.395 16.215L4.24178 16.7562C4.40337 16.802 4.57694 16.7731 4.71505 16.6776C4.85315 16.582 4.94134 16.4298 4.9555 16.2624L4.395 16.215ZM13.605 16.215L13.0445 16.2624C13.0587 16.4298 13.1468 16.582 13.285 16.6776C13.4231 16.7731 13.5966 16.802 13.7582 16.7562L13.605 16.215ZM6.75 0.9375C4.81952 0.9375 3.32185 1.32015 2.321 2.321C1.32015 3.32185 0.9375 4.81952 0.9375 6.75H2.0625C2.0625 4.93048 2.42985 3.80315 3.1165 3.1165C3.80315 2.42985 4.93048 2.0625 6.75 2.0625V0.9375ZM0.9375 6.75V11.25H2.0625V6.75H0.9375ZM0.9375 11.25C0.9375 12.713 1.15611 13.9231 1.69494 14.8596C2.2471 15.8192 3.10164 16.4335 4.24178 16.7562L4.54822 15.6738C3.64836 15.419 3.0554 14.9683 2.67006 14.2985C2.27139 13.6057 2.0625 12.622 2.0625 11.25H0.9375ZM4.9555 16.2624C5.08828 14.6931 6.75539 13.29 9 13.29V12.165C6.36961 12.165 4.03172 13.8369 3.8345 16.1676L4.9555 16.2624ZM9 13.29C11.2446 13.29 12.9117 14.6931 13.0445 16.2624L14.1655 16.1676C13.9683 13.8369 11.6304 12.165 9 12.165V13.29ZM13.7582 16.7562C14.8984 16.4335 15.7529 15.8192 16.3051 14.8596C16.8439 13.9231 17.0625 12.713 17.0625 11.25H15.9375C15.9375 12.622 15.7286 13.6057 15.3299 14.2985C14.9446 14.9683 14.3516 15.419 13.4518 15.6738L13.7582 16.7562ZM17.0625 11.25V6.75H15.9375V11.25H17.0625ZM17.0625 6.75C17.0625 4.81952 16.6798 3.32185 15.679 2.321C14.6781 1.32015 13.1805 0.9375 11.25 0.9375V2.0625C13.0695 2.0625 14.1969 2.42985 14.8835 3.1165C15.5702 3.80315 15.9375 4.93048 15.9375 6.75H17.0625ZM11.25 0.9375H6.75V2.0625H11.25V0.9375ZM6.8775 7.93501C6.8775 6.76067 7.82566 5.8125 9 5.8125V4.6875C7.20434 4.6875 5.7525 6.13936 5.7525 7.93501H6.8775ZM9 5.8125C10.1743 5.8125 11.1225 6.76067 11.1225 7.93501H12.2475C12.2475 6.13936 10.7957 4.6875 9 4.6875V5.8125ZM11.1225 7.93501C11.1225 9.11139 10.1723 10.065 9 10.065V11.19C10.7977 11.19 12.2475 9.72863 12.2475 7.93501H11.1225ZM9 10.065C7.82769 10.065 6.8775 9.11139 6.8775 7.93501H5.7525C5.7525 9.72863 7.20231 11.19 9 11.19V10.065Z"
											fill="white"></path>
										<path
											d="M8.99994 10.6275C7.51494 10.6275 6.31494 9.42001 6.31494 7.93501C6.31494 6.45001 7.51494 5.25 8.99994 5.25C10.4849 5.25 11.6849 6.45001 11.6849 7.93501C11.6849 9.42001 10.4849 10.6275 8.99994 10.6275Z"
											stroke="white" stroke-width="1.125" stroke-linecap="round"
											stroke-linejoin="round"></path>
									</svg>
								</span>
								Sign Up
							</a>
						</div>
					</div>
				</div>
				<div class="content" style="background-color: #FFFFFF">
					<div class="left" style="background-color: #FFFFFF;background-image: none;">
						<!-- <div class="el el-1"><img :src="require('@/assets/img/dashboardPages/blue_sphere.png')" alt="">
							<span class="el-image"><img
									:src="require('@/assets/img/dashboardPages/signUp-back1-el.png')" alt=""></span>
						</div>
						<div class="el el-2"><img :src="require('@/assets/img/dashboardPages/pink_figure.png')" alt="">
						</div>
						<div class="el el-3"><img :src="require('@/assets/img/dashboardPages/figure_2.png')" alt="">
						</div>
						<div class="el el-4"><img :src="require('@/assets/img/dashboardPages/figure_3.png')" alt="">
						</div>
						<div class="el el-5"><img :src="require('@/assets/img/dashboardPages/sphere_2.png')" alt="">
						</div> -->
						<div class="wrapper" style="background-color: #FFFFFF;">
							<div class="block" style="background-color: #FFFFFF;">
								<div class="info" style="background-color: #FFFFFF;">
									<div class="info-block" style="background-color: #FFFFFF;">
										<div>
											<img :src="require('@/assets/img/login/pic_denglu.png')" alt=""
												style="width: 40%;margin: 50px auto ;">
										</div>
										<h1 class="title" style="    font-size: 32px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
	color:#165ba4;
    font-weight: 700;
	text-align: center;
    margin-bottom: 30px;">Your payments support UNICEF</h1>
										<h2 class="subtitle" style="font-size: 16px;
    color: #6f7d8d;
	text-align: center;
    line-height: 28px;">We take a portion of every SEPA Instant and Bankera
											busioness card pament you make ande donate it to help UNICEF.</h2>
									</div>

									<p class="description" id="dateYear"></p>

								</div>
							</div>
						</div>

					</div>
					<div class="right" style="background-color: #f5f6fa;background-image: none">
						<div class="wrapper">
							<div class="block" v-if="gooGelcode">
								<h2 class="title" style="color: #165ba4;">Sign In</h2>
								<form name="signin">
									<div class="block-item default-input">
										<div class="default-input__top">
											<label for="Email" class="label" style="font-size: 14px;color: #165ba4;">
												Email
											</label>
										</div>
										<input type="email" class="input" v-model="loginName" name="username"
											maxlength="50" autocomplete="off">
									</div>
									<div class="block-item default-input">
										<div class="default-input__top">
											<label class="label" style="font-size: 14px;color: #165ba4;">
												Password
											</label>
										</div>
										<input type="password" class="input" v-model="password" name="password"
											maxlength="30" autocomplete="off">
									</div>
									<div class="block-button">
										<div @click="onSubmit()" class="default-button" style="cursor: pointer;">
											Sign In
										</div>
									</div>
								</form>
								<a href="/forgotPassword" class="block-signIn signIn"
									style="font-size: 14px;color: #165ba4;">
									<span class="icon">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M8.265 14.625H5.625C5.16 14.625 4.7475 14.61 4.38 14.5575C2.4075 14.34 1.875 13.41 1.875 10.875V7.125C1.875 4.59 2.4075 3.66 4.38 3.4425C4.7475 3.39 5.16 3.375 5.625 3.375H8.22"
												stroke="white" stroke-width="1.125" stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path
												d="M11.2651 3.375H12.3751C12.8401 3.375 13.2526 3.39 13.6201 3.4425C15.5926 3.66 16.1251 4.59 16.1251 7.125V10.875C16.1251 13.41 15.5926 14.34 13.6201 14.5575C13.2526 14.61 12.8401 14.625 12.3751 14.625H11.2651"
												stroke="white" stroke-width="1.125" stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path d="M11.25 1.5V16.5" stroke="white" stroke-width="1.125"
												stroke-linecap="round" stroke-linejoin="round"></path>
											<path d="M6 6.375V11.625" stroke="white" stroke-width="1.125"
												stroke-linecap="round" stroke-linejoin="round"></path>
										</svg>
									</span>
									Forgot Password?
								</a>
							</div>
							<div class="block" style="display: block;" v-else>
								<h2 class="title">2 Factor Authentication</h2>
								<h3 class="subtitle">Please enter the 2FA code here, from your virtual 2FA device</h3>
								<form>
									<div class="block-item default-input  center">
										<input type="text" class="input" style="text-align: center;"
											placeholder="2FA Code" v-model="securityCode">
									</div>
									<div class="block-button">
										<div type="submit" class="default-button" @click="onSubmit()">Submit</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import {
	ElMessage
} from 'element-plus';
import local from "@/utils/local";
import {
	login,
	getIsOpenSecond
} from "@/api/login";
import {
	getUserInfo
} from '@/api/user'
import {
	openValid,
	openGoogleAuth,
	getGoogleAuth
} from '@/api/setting'
export default {
	name: 'LoginComp',
	components: {

	},
	created() { },
	mounted() { },
	computed: {
		userInfo() {
			return this.$store.state.userInfo
		},
	},
	data() {
		return {
			password: '',
			loginName: '',
			isEnable2FA: '',
			gooGelcode: true,
			securityCode: ''
		}
	},

	methods: {
		initData() {
			this.$pu.showLoading()
			// Promise.all().then(results=>{

			// })
			getUserInfo().then(res => {
				this.$pu.hideLoading()
				if (res.code === 200) {
					this.isEnable2FA = res.data.hasGoogleAuth
					this.emailAddress = res.data.email
				} else {
					this.$message.error(res.msg)
				}
			}).catch(error => {
				this.$pu.hideLoading()
				this.$message.error(error)
			})
		},
		enable2FA() {
			this.$pu.showLoading()
			const params = {
				securityCode: this.securityCode
			}
			openGoogleAuth(params).then(res => {
				this.$pu.hideLoading()
				if (res.code === 200) {
					this.$message.success('2FA enabled successfully!')
					let _this = this
					setTimeout(() => {
						this.$router.push('/accountDashboard');
					}, 1500);
				} else {
					this.$message.error(res.msg)
				}
			}).catch(error => {
				this.$pu.hideLoading()
				this.$message.error(error)
			})
		},
		async onSubmit() {
			if (!this.loginName) {
				this.$message.error('PLEASE ENTER Email FIRST!')
				return
			}
			if (!this.password) {
				this.$message.error('PLEASE ENTER Password FIRST!')
				return
			}
			if (this.gooGelcode == false && !this.securityCode) {
				this.$message.error('PLEASE ENTER 2FA code FIRST!')
				return
			}
			let data = {
				password: this.password,
				loginName: this.loginName
			}
			if (this.securityCode) {
				data.googleValidateCode = this.securityCode
			}
			this.$pu.showLoading()
			getIsOpenSecond({
				loginName: this.loginName
			}).then(res => {
				if (res.code == 200) {
					//开启了Auth 显示 2FA面板
					if (res.data && !data.googleValidateCode) {
						this.$pu.hideLoading()
						this.gooGelcode = false
						return new Promise(() => { })
					} else {
						return login(data)
					}
				} else {
					this.$message.error(res.msg)
				}
			}).then(res => {
				this.$pu.hideLoading()
				if (res.code == 200) {
					local.set("tk", res.data.token);
					local.set("email", res.data.email);

					this.$pu.showLoading()
					getUserInfo().then(res => {
						this.$pu.hideLoading()
						if (res.code === 200) {
							this.isEnable2FA = res.data.hasGoogleAuth
							this.emailAddress = res.data.email
							let userInfo = res.data
							if (userInfo.registerToBank == false) {
								this.$router.push('/registration');
							} else if (userInfo.hasGoogleAuth == false) {
								this.$router.push('/dashboard');
							} else {
								this.$router.push('/accountDashboard')
								// this.gooGelcode = false
							}
						} else {
							this.$message.error(res.msg)
						}
					})



					// this.initData()
					// setTimeout(() => {
					// 	let isEnable2F = JSON.parse(JSON.stringify(this.userInfo))
					// 	if (isEnable2F.hasGoogleAuth == false) {
					// 		this.$router.push('/dashboard');
					// 	} else {
					// 		this.$router.push('/accountDashboard')
					// 		// this.gooGelcode = false
					// 	}
					// }, 1000);
				} else {
					ElMessage.error(res.msg)
				}
			})

			// await login(data).then(res => {
			// 	this.$pu.hideLoading()
			// 	if (res.code == 200) {
			// 		local.set("tk", res.data.token);
			// 		local.set("email", res.data.email);
			// 		this.initData()
			// 		setTimeout(() => {
			// 			let isEnable2F = JSON.parse(JSON.stringify(this.userInfo))
			// 			if (isEnable2F.hasGoogleAuth == false) {
			// 				this.$router.push('/dashboard');
			// 			} else {
			// 				this.gooGelcode = false
			// 			}
			// 		}, 1000);
			// 	} else {
			// 		ElMessage.error(res.msg)
			// 	}
			// })
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/libs.css';
@import '@/assets/css/dashboard.css';
@import '@/assets/css/custom.css';

.default-input {
	.input {
		color: #072761;
		border: 1px solid #072761;
	}

	input {
		color: #072761;
		border: 1px solid #072761;
	}

	input::placeholder {
		color: #072761;
	}

	.input::placeholder {
		color: #072761;
	}
}

.signUp {
	.right {
		.title {

			color: #072761;
		}

		.subtitle {

			color: #072761;
		}
	}
}
</style>