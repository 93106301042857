import request from "@/utils/request";
//添加联系人 - 邮箱
export const addRecipient = (data) =>
  request({
    url: "/contact/addContact",
    method: "post",
    data,
  });
//联系人分页
export const getRecipientPage = (data) =>
  request({
    url: "/contact/page",
    method: "post",
    data,
  });
//联系人列表
export const getRecipientList = () =>
  request({
    url: "/contact/list",
    method: "get"
  });
//移除联系人
export const removeRecipient = (recipientId) =>
  request({
    url: "/contact/delete/" + recipientId,
    method: "post"
  });