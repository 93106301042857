<template>
  <MainContentComp header-title="Fee Table">
    <section class="transactions block">
      <div class="wrapper">
        <div class="transactions__content">
          <table cellpadding="0" cellspacing="0" class="alignCentre">
            <thead>
              <tr>
                <th style="text-align: center">Fees</th>
                <th style="text-align: center">Description</th>
                <th style="text-align: center">Value</th>
                <th style="text-align: center">Payers</th>
                <th style="text-align: center">Amount</th>
                <th style="text-align: center">Currency</th>

              </tr>
            </thead>
            <tbody id="fetchfeetable">
              <!-- <tr>
                <td data-label="fee_code" style="text-align: center">ACTCR</td>
                <td data-label="description" style="text-align: center">Account Creation</td>
                <td data-label="v_p_flag" style="text-align: center">Fixed Value</td>
                <td data-label="payer_flag" style="text-align: center">User</td>
                <td data-label="value" style="text-align: center">1.00</td>
                <td data-label="currency_code" style="text-align: center">USD</td>
              </tr>
              <tr>
                <td data-label="fee_code" style="text-align: center">MOMTI</td>
                <td data-label="description" style="text-align: center">Monthly Maintenance for Inactive Accounts</td>
                <td data-label="v_p_flag" style="text-align: center">Fixed Value</td>
                <td data-label="payer_flag" style="text-align: center">User</td>
                <td data-label="value" style="text-align: center">1.00</td>
                <td data-label="currency_code" style="text-align: center">USD</td>
              </tr> -->
              <tr>
                <td data-label="fee_code" style="text-align: center">W2W</td>
                <td data-label="description" style="text-align: center">Peer to Peer Fee</td>
                <td data-label="v_p_flag" style="text-align: center">{{ fetchFeeType(useRate) }}</td>
                <td data-label="payer_flag" style="text-align: center">User</td>
                <td data-label="value" style="text-align: center">{{ feeVal }}</td>
                <td data-label="currency_code" style="text-align: center">USD</td>
              </tr>
              <!-- <tr>
                <td data-label="fee_code" style="text-align: center">M2P</td>
                <td data-label="description" style="text-align: center">Admin to User Load Fee</td>
                <td data-label="v_p_flag" style="text-align: center">Percentage</td>
                <td data-label="payer_flag" style="text-align: center">User</td>
                <td data-label="value" style="text-align: center">0.00</td>
                <td data-label="currency_code" style="text-align: center">USD</td>
              </tr>
              <tr>
                <td data-label="fee_code" style="text-align: center">LOAD</td>
                <td data-label="description" style="text-align: center">Admin Loading Fee</td>
                <td data-label="v_p_flag" style="text-align: center">Percentage</td>
                <td data-label="payer_flag" style="text-align: center">Company</td>
                <td data-label="value" style="text-align: center">0.50</td>
                <td data-label="currency_code" style="text-align: center">USD</td>
              </tr>
              <tr>
                <td data-label="fee_code" style="text-align: center">ADVCARD</td>
                <td data-label="description" style="text-align: center">Add Virtual card fees</td>
                <td data-label="v_p_flag" style="text-align: center">Fixed Value</td>
                <td data-label="payer_flag" style="text-align: center">User</td>
                <td data-label="value" style="text-align: center">1.00</td>
                <td data-label="currency_code" style="text-align: center">USD</td>
              </tr>
              <tr>
                <td data-label="fee_code" style="text-align: center">ADPCARD</td>
                <td data-label="description" style="text-align: center">Add Physical card fees</td>
                <td data-label="v_p_flag" style="text-align: center">Fixed Value</td>
                <td data-label="payer_flag" style="text-align: center">User</td>
                <td data-label="value" style="text-align: center">0.00</td>
                <td data-label="currency_code" style="text-align: center">USD</td>
              </tr>
              <tr>
                <td data-label="fee_code" style="text-align: center">MOMTCP</td>
                <td data-label="description" style="text-align: center">Monthly Maintenance for Physical card</td>
                <td data-label="v_p_flag" style="text-align: center">Fixed Value</td>
                <td data-label="payer_flag" style="text-align: center">User</td>
                <td data-label="value" style="text-align: center">1.00</td>
                <td data-label="currency_code" style="text-align: center">USD</td>
              </tr>
              <tr>
                <td data-label="fee_code" style="text-align: center">MOMTCV</td>
                <td data-label="description" style="text-align: center">Monthly Maintenance for Virtual card</td>
                <td data-label="v_p_flag" style="text-align: center">Fixed Value</td>
                <td data-label="payer_flag" style="text-align: center">User</td>
                <td data-label="value" style="text-align: center">0.95</td>
                <td data-label="currency_code" style="text-align: center">USD</td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>


      <div class="modal fetchfeeModal" data-modal="fetchfeeModal">

        <div class="modal-block">
          <div class="modal-top">
            <div class="tabs">
              <h1 class="modal-title">Congratulations!</h1>

            </div>
            <div class="modal-close buttonClose" id="hiddenclosefee">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 11L11 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
                <path d="M11 11L1 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
              </svg>
            </div>
          </div>
          <div class="fetchfeeModal-main active">

            <div class="fetchfeeModal-top">
              <p style="font-size: 15px;">
                <strong>Your statement is ready for download</strong>
              </p>
              <!-- <input type="text"> -->
              <!-- <p>0Â£</p> -->
            </div>
            <div class="cryptoAccountModal-bottom">
              <button type="button" class="button" onclick="exportAccountStatementList()">Download as
                .csv</button>
              <button type="button" class="button" onclick="fetchAccDetails()">Download
                as .pdf</button>
              <div class="info"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </MainContentComp>
</template>

<script>
import MainContentComp from '@/components/section/mainComp.vue'
import { getTransferRate } from '@/api/rate'
export default {
  name: 'FeeTable',
  components: {
    MainContentComp
  },
  props: {
    msg: String
  },
  data() {
    return {
      useRate: undefined,
      feeVal: ''
    }
  },
  created() { },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.$pu.showLoading()
      const rateParams = {
        "coinLink": "USD",
        "coinName": "USD",
        "inputAmount": "0",
        "type": 0
      }
      getTransferRate(rateParams).then(res => {
        this.$pu.hideLoading()
        if (res.code === 200) {
          this.useRate = res.data.useRate
          if(this.useRate){
            this.feeVal = res.data.rate
          }else{
            this.feeVal = res.data.charge
          }
        } else {

        }
      })
    },
    fetchFeeType(val) {
      if (val === undefined) {
        return ''
      } else if (val == false) {
        return 'Fixed Value'
      } else {
        return 'Percentage'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.css';
</style>
