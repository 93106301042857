import Store from '@/store/index'
const pageUtils = {
    showLoading: () => {
        Store.commit('switch_loading', true)
    },
    hideLoading: () => {
        Store.commit('switch_loading', false)
    },
}

export default pageUtils
