<template>
	<MainContentComp headerTitle="Account Details">
		<section class="account__content block block__active">
			<div class="wrapper">
				<div class="profile-info">
					<div class="account__content-top">
						<div class="tabs">
							<div class="tab" @click="changeRules(index)" :class="{ active: allTabsIndex == index }"
								v-for="(item, index) in tabs" :key="index">
								{{ item.name }}
							</div>
						</div>
					</div>

					<div class="container active" v-if="allTabsIndex == 0">
						<div class="block">
							<form action="#">
								<div class="items">
									<div class="default-input item displayNone" id="accountNumberDiv">
										<label>Account number</label>
										<select class="select2-hidden-accessible" id="accountNumber"
											onchange="changeAccountDetails();" data-select2-id="accountNumber"
											tabindex="-1" aria-hidden="true">
											<option selected="selected" value="b9f15c705405bb00d54167dc7fd6df1c"
												data-select2-id="4">6377024700133434</option>
										</select>
										<span class="select2 select2-container select2-container--default" dir="ltr"
											data-select2-id="3" style="width: auto;">
											<span class="selection">
												<span class="select2-selection select2-selection--single"
													role="combobox" aria-haspopup="true" aria-expanded="false"
													tabindex="0" aria-disabled="false"
													aria-labelledby="select2-accountNumber-container">
													<span
														class="select2-selection__rendered select2ModifyAccountDetails"
														id="select2-accountNumber-container" role="textbox"
														aria-readonly="true"
														title="6377024700133434">6377024700133434</span>
													<span class="select2-selection__arrow" role="presentation">
														<b role="presentation">
														</b>
													</span>
												</span>
											</span>
											<span class="dropdown-wrapper" aria-hidden="true">
											</span>
										</span>
									</div>
									<div class="default-input item">
										<label>Balance</label>
										<div class="input">
											<input type="text" v-model="itemList.availableBalance" id="accountBalance"
												readonly="">
										</div>
									</div>
									<div class="default-input item">
										<label>Account type</label>
										<div class="input">
											<input type="text" v-model="itemList.type" id="accountType" readonly="">
										</div>
									</div>

									<div class="default-input item">
										<label>Account Status</label>
										<div class="input">
											<input type="text" v-model="itemList.status" id="accountStatus" readonly="">
										</div>
									</div>

								</div>
								<div class="items">
									<!-- <div class="default-input item" id="routingNumberDiv" style="display: none;">
                                        <label>Routing number</label>
                                        <div class="input">
                                            <input type="text" value="" id="routingNumber" readonly="">
                                        </div>
                                    </div> -->
									<div class="default-input item">
										<label>Date opened</label>
										<div class="input">
											<input type="text" v-model="itemList.createdAt" id="createdDate"
												readonly="">
										</div>
									</div>

								</div>
								<div class="items">


								</div>
							</form>
						</div>
					</div>
					<div class="container" style="display: block;" v-else>
						<div class="transactions statement">
							<div class="transactions__top">
								<!-- <h2 class="content__title transactions__title">Statement</h2> -->
								<!-- 
                            		<div class="statement-sorts" id="transactionsAccountChooseDiv" >
                            						<p class="statement-sort__text">Choose Account</p>
                            					
                            						<select class="" id="transactionsAccountChoose" onchange="changeTransactionList();">
                            							  		
                            						</select>
                            		</div> -->

								<!-- <div class="statement-sorts" id="listcard">
                                            <label>Bank Name</label>
                                            <select class="default-select" data-placeholders='Choose Card' onchange="toggleCard()" id="optionsDropdown" >
                                               <option>
                                                
                                               </option>
                                            </select>
                                     </div> -->

								<!-- <div class="statement-sorts">
                            						<p class="statement-sort__text">Sort by</p>
                            						 <input type="text" class="statementDate" placeholder="Date" id="accountStatementDate">
                            			
                            		</div> -->
								<form class="date-filter">
									<div class="default-input date-filter__input"
										style="display: flex;justify-content: center;align-items: center; gap:8px; margin-bottom: 5px;">
										<!-- <input type="text" class="statement-sorts__dates" placeholder="Start Date"
                                            id="accountStatementDatestartdate" autocomplete="off"
                                            style="margin-right: 15px; border:1px solid gray;"> -->
										<el-date-picker v-model="startDate" type="date" placeholder="Start Date"
											autocomplete="off" :class="'statement-sorts__dates'" :prefix-icon="null"
											format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
										<el-date-picker v-model="endDate" type="date" placeholder="End Date"
											autocomplete="off" :class="'statement-sorts__dates'" :prefix-icon="null"
											value-format="YYYY-MM-DD" />
										<!-- <input type="text" class="statement-sorts__dates2" placeholder="End Date"
                                            id="accountStatementDateenddate" autocomplete="off"
                                            style="margin-right: 15px;border:1px solid gray;"> -->
										<button type="button" class="main-btn date-filter__submit" style="width: auto;"
											@click="loadPage(1)">Search</button>
									</div>
								</form>

								<div class="transactions__btn">
									<a id="fetchfeehidden" style="background: #ffffff;color: #113bde;"
										class="button modal-openButton" data-modal="fetchfeeModal" href="#"
										@click="clickFunction">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
											viewBox="0 0 20 20">
											<g>
												<g>
													<path class="export__svg" fill="#3246d3"
														d="M9 1a1 1 0 0 1 2 0v11.586l1.293-1.293a1 1 0 0 1 1.414 1.414l-2.95 2.95a1 1 0 0 1-1.464.05l-3-3a1 1 0 0 1 1.414-1.414L9 12.586zm10 17a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z">
													</path>
												</g>
											</g>
										</svg> Get Statement
									</a>
								</div>
							</div>
							<div class="transactions__content">
								<table cellpadding="0" cellspacing="0">
									<thead>
										<tr>
											<th style="text-align: center">Date</th>
											<!-- <th>Time</th> -->
											<!-- <th style="text-align: center">Recipient's Name</th> -->
											<th style="text-align: center">Transactions Remarks</th>
											<!-- <th style="text-align: left">Mode</th> -->
											<th style="text-align: center">Amount</th>
											<!-- <th style="text-align: center">Status</th> -->
											<!-- <th style="text-align: center">Type</th> -->
											<th id="balance" style="text-align: center">Balance</th>
										</tr>
									</thead>
									<tbody id="accountStatementTableBody">
										<tr v-for="(item, index) in dataList" :key="item.id">
											<td style="text-align: center">{{ formattedUTCDate(item.date) }}</td>
											<!-- <td>Time</td> -->
											<!-- <td style="text-align: center"></td> -->
											<td style="text-align: center">{{ item.description }}</td>
											<!-- <td style="text-align: left">Mode</td> -->
											<td style="text-align: center">{{ item.amount }}</td>
											<!-- <td style="text-align: center">Status</td> -->
											<!-- <td style="text-align: center">Type</td> -->
											<td id="balance" style="text-align: center">{{ item.balance }}</td>
										</tr>
									</tbody>
								</table>
								<div class="transactions__view">
									<!-- <a href="#">View all</a> -->
								</div>


								<!-- <div class="statement-pagination pagination"> -->
								<!-- <div class="statement-pagination pagination">
							        <div class="pagination-info">
							           <p id="paginationHeading">Showing <span>1</span> to <span>5</span> of <span>5</span> entries</p>
							        </div>
							        <div class="pagination-blocks">
							            <div class="pagination-next pagination-button" id="prevButtonDiv"
							                onclick="previousValue();">
							                <svg width="8" height="14" viewBox="0 0 8 14" fill="none"
							                    xmlns="http://www.w3.org/2000/svg">
							                    <path d="M7 1L2.41421 5.58579C1.63316 6.36684 1.63317 7.63317 2.41421 8.41421L7 13"
							                        stroke="#1A1B26" stroke-width="2" stroke-linecap="round"
							                        stroke-linejoin="round">
							                    </path>
							                </svg>
							            </div>
							            <div class="pagination-block" id="paginationValue">1</div>
							            <div class="pagination-prev pagination-button displayNone" id="nextButtonDiv"
							                onclick="nextValue();">
							                <svg width="8" height="14" viewBox="0 0 8 14" fill="none"
							                    xmlns="http://www.w3.org/2000/svg">
							                    <path
							                        d="M1 13.0001L5.58579 8.41427C6.36684 7.63323 6.36683 6.3669 5.58579 5.58585L1 1.00006"
							                        stroke="#1A1B26" stroke-width="2" stroke-linecap="round"
							                        stroke-linejoin="round">
							                    </path>
							                </svg>
							            </div>
							        </div>
							    </div> -->
								<!-- <el-pagination background layout="prev, pager, next" :total="50" :current-page="1"> </el-pagination> -->
								<NElPagination layout="prev, pager, next" :total="total" v-model:currentPage="page"
									background v-model:page-size="limit" @current-change="onPageChange"
									class="transaction-pager" style="justify-content: flex-end" v-if="total > 0"
									</NElPagination>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal fetchfeeModal" data-modal="fetchfeeModal"
				:style="{ display: isShowDownloadPanel ? 'flex' : 'none' }">
				<div class="modal-block">
					<div class="modal-top">
						<div class="tabs">
							<h1 class="modal-title">
								Congratulations!
							</h1>

						</div>
						<div class="modal-close buttonClose" id="hiddenclosefee" @click="hideDownloadPanel">
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M1 11L11 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round">

								</path>
								<path d="M11 11L1 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round">

								</path>
							</svg>
						</div>
					</div>
					<div class="fetchfeeModal-main active">

						<div class="fetchfeeModal-top">
							<p style="font-size:15px;">
								<strong>Your statement is ready for download</strong>
							</p>
							<!-- <input type="text"> -->
							<!-- <p>0Â£</p> -->
						</div>
						<div class="cryptoAccountModal-bottom">
							<button type="button" class="button" @click.stop="exportAccountStatementList">Download as
								.csv</button>
							<button type="button" class="button" @click.stop="fetchAccDetails">Download as .pdf</button>
							<div class="info">
							</div>
						</div>
					</div>
				</div>
			</div>

		</section>
	</MainContentComp>
</template>

<script>
import {
	getYMD
} from '@/utils/date'
import moment from 'moment-timezone'
import {
	ElPagination as NElPagination
} from 'element-plus';
import {
	getTransactionPage,
	downloadPdf,
	downloadCsv,
	globalAccount
} from '@/api/transaction'
import MainContentComp from '@/components/section/mainComp.vue'
export default {
	name: 'FeeTable',
	components: {
		MainContentComp,
		NElPagination
	},
	props: {
		msg: String
	},
	data() {
		return {
			itemList: {},
			transactionType: '',
			startDate: '',
			endDate: '',
			page: 1,
			pageSize: 10,
			limit: 10,
			offset: 0,
			total: 0,
			cacheDataList: [],
			dataList: [],
			isShowDownloadPanel: false,
			hasNext: true,
			allTabsIndex: 0,
			tabs: [{
				name: 'Account details',
				index: 0
			},
			{
				name: 'Account statement',
				index: 1
			}
			]
		}
	},
	mounted() {
		this.itemList = this.$route.query
		this.initData()
		this.getGlobalAccount()
	},
	methods: {
		getGlobalAccount() {
			this.$pu.showLoading()
			globalAccount().then(res => {
				this.$pu.hideLoading()
				if (res.code == 200) {
					this.globalAccountList = res.data.map((item) => {
						item.show = true;
						item.visible = false;
						return item;
					});
					this.itemList = res.data[0]
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		initData() {
			this.startDate = this.getDayMonth(-1, new Date())
			this.endDate = getYMD('-')
			this.loadPage(1)
		},
		changeRules(e) {
			this.allTabsIndex = e
		},
		nextPage() {
			if (this.hasNextPage) {
				this.loadPage(this.page + 1)
			}
		},
		loadPage(page) {
			console.log("page: ", page, " cacheDataList.length: ", this.cacheDataList.length)
			if (page <= this.cacheDataList.length) {
				this.dataList = this.cacheDataList[page - 1]
				return
			}
			this.$pu.showLoading()
			const params = {
				"startDate": this.startDate,
				"limit": this.limit,
				"offset": (page - 1) * this.limit,
				"endDate": this.endDate,
				"page": page,
				"pageSize": this.pageSize
			}
			console.log("params: " + JSON.stringify(params, null, '\t'))
			getTransactionPage(params).then(res => {
				this.$pu.hideLoading()
				if (res.code === 200) {
					if (!res.data.list && res.data.list.length == 0) {
						this.$message.error('No transactions found!')
						this.hasNext = false;
					} else if (res.data.list.length < this.limit) {
						this.page = res.data.param.page
						this.hasNext = false
						this.total = this.page * this.limit
						this.dataList = res.data.list
						if (this.cacheDataList[this.page - 1]) {
							this.cacheDataList[this.page - 1] = res.data.list
						} else {
							this.cacheDataList.push(res.data.list)
						}
					} else {
						this.page = res.data.param.page
						this.hasNext = true
						this.total = (this.page * this.limit) + 1
						this.dataList = res.data.list
						if (this.cacheDataList[this.page - 1]) {
							this.cacheDataList[this.page - 1] = res.data.list
						} else {
							this.cacheDataList.push(res.data.list)
						}
					}
				} else {
					console.log("res.msg: ", res.msg)
					this.$message.error(res.msg)
				}
			}).catch(error => {
				console.log("error: ", error)
				this.$pu.hideLoading()
				this.$message.error(error)
			})
		},
		onPageChange(val) {
			this.loadPage(val)
		},
		onStartChanged(val) {
			console.log(val)
		},
		onEndDateChanged(cal) { },
		formattedUTCDate(utcTime) {
			let timezoneName = moment.tz.guess(true)
			return moment(utcTime, timezoneName).format('YYYY-MM-DD HH:mm:ss')
		},
		clickFunction() {
			this.isShowDownloadPanel = true
		},
		hideDownloadPanel() {
			this.isShowDownloadPanel = false
		},
		exportAccountStatementList() {
			const params = {
				"startDate": this.startDate,
				"limit": this.limit,
				"offset": (this.page - 1) * this.limit,
				"endDate": this.endDate,
				"page": this.page,
				"pageSize": this.pageSize
			}
			downloadCsv(params).then(res => {
				const blob = new Blob([res], {
					type: 'application/csv'
				})
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = this.startDate + "-" + this.endDate + 'accountStatement.csv'
				link.click()
			}).catch(error => {
				this.$message.error(error)
			})
		},
		fetchAccDetails() {
			const params = {
				"startDate": this.startDate,
				"limit": this.limit,
				"offset": (this.page - 1) * this.limit,
				"endDate": this.endDate,
				"page": this.page,
				"pageSize": this.pageSize
			}
			downloadPdf(params).then(res => {
				const blob = new Blob([res], {
					type: 'application/pdf'
				})
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = this.startDate + "-" + this.endDate + 'accountStatement.pdf'
				link.click()
			}).catch(error => {
				this.$message.error(error)
			})
		},
		getDayMonth(mode = 0, date = new Date()) {
			let y = date.getFullYear()
			let m = date.getMonth() + 1
			let d = date.getDate()

			console.log("mode: ", mode)
			if (mode == -1) {
				d = 1
			}
			console.log("y: ", y)
			console.log("m: ", m)
			console.log("d: ", d)
			return [y, m, d].map(function (n) {
				return n < 10 ? '0' + n : n; // [2021, 07, 08]
			}).join('-')
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.css';
@import '@/assets/css/libs.css';
@import '@/assets/css/modal.css';
@import '@/assets/css/conversions.css';
@import '@/assets/css/transactions.css';

.modal-block {
	margin: auto;
	background: #fff;
	border-radius: 20px;
	width: 33%;
	position: relative;
	padding: 30px 28px 10px;
}

:deep(.default-input .input) {
	border: none;
	padding: 0;
}

.modal-block {
	margin: auto;
	background: #fff;
	border-radius: 20px;
	width: 33%;
	position: relative;
	padding: 30px 28px 10px;
}

.demo-date-picker {
	display: flex;
	width: 100%;
	padding: 0;
	flex-wrap: wrap;
	background: #fff;
	border-radius: 10px;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #1a1b26;
	border: none;
	width: 100%;
	padding: 15px 20px;
	font-family: "GothamPro", sans-serif;
}

.demo-date-picker .block {
	padding: 30px 0;
	text-align: center;
	border-right: solid 1px var(--el-border-color);
	flex: 1;
}

.demo-date-picker .block:last-child {
	border-right: none;
}

.demo-date-picker .demonstration {
	display: block;
	color: var(--el-text-color-secondary);
	font-size: 14px;
	margin-bottom: 20px;
}

:deep() {
	.el-input__prefix {
		display: none;
	}
}

.noTextTransaformation {
	color: #e4e4f3 !important;
}

.statusRed {
	color: #de1f1f !important;
}

.statusGreen {
	color: #1fde27 !important;
}

.select2-container--default .select2-selection--single {
	border-radius: 9px;
	border: 2px solid #eff1f7;
	height: 52px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	top: 92%;
}

.bigdrop {
	width: 90px !important;
}

.widthChange {
	max-width: 11% !important;
}

.select3 {
	background: #fff;
	border-radius: 10px;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #1a1b26;
	width: 100%;
	padding: 15px 10px;
	font-family: "GothamPro", sans-serif;
	border: 2px solid #eff1f7;
	text-align: center;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #0b2af7;
	line-height: 50px;
}

:deep() {

	.el-date-editor.el-input,
	.el-date-editor.el-input__wrapper {
		height: 52px;
	}

	.statement-sorts__dates {
		border: 0;
		height: unset;

		.el-input__wrapper {
			border: 1px solid gray !important;
			background: #fff;
			border-radius: 10px;
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
			color: #1a1b26;
			border: none;
			width: 100%;
			padding: 15px 20px;
			font-family: "GothamPro", sans-serif;

			.el-input__inner {
				padding: 15px 0;
			}
		}
	}

	.transaction-pager {

		.btn-prev,
		.btn-next {
			height: 42px;
		}

		.el-pager {
			.number {
				background: #f5f6fa;
				border-radius: 10px;
				font-weight: 500;
				font-size: 15px;
				line-height: 20px;
				text-align: center;
				color: #3246d3;
				padding: 11px 10px;
				min-width: 70px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				margin: 0 5px;
				height: unset;
			}
		}
	}
}

.transactions__topNew {
	display: flex !important;
}
</style>