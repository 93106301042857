<template>
	<div class="dashboard signUpPage">
	        <div class="modal changeInfo" :class="isShowEnable2FAPanel ? 'active' : ''" data-modal="enable2FA">
	            <div class="modal-block">
	                <div class="modal-top">
	                    <h2 class="modal-title">Enable 2FA</h2>
	                </div>
	                <div class="message">
	                    <span class="icon">
	                        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
	                            <path d="M15 9V18.8949" stroke="#FF5656" stroke-width="2" stroke-linecap="round"></path>
	                            <path d="M15 22.4727L15 23.304" stroke="#FF5656" stroke-width="2" stroke-linecap="round">
	                            </path>
	                            <path
	                                d="M18.4966 4.23481C16.9726 1.49157 13.0274 1.49157 11.5034 4.2348L1.60191 22.0574C0.120734 24.7236 2.04861 28 5.09854 28H24.9015C27.9514 28 29.8793 24.7236 28.3981 22.0574L18.4966 4.23481Z"
	                                stroke="#FF5656" stroke-width="2"></path>
	                        </svg>
	                    </span>
	                    <p>On enabling 2FA, will ask for additional login code during log-in.</p>
	                </div>
	                <h4 style="margin-top: 5px;font-weight: 500;">Set up virtual 2FA device</h4>
	                <div class="block">
	                    <p class="block-text">1. Install an authenticator app on your mobile device or computer</p>
	                    <p class="block-text">2. Use your virtual 2FA app and your device's camera to scan the QR code</p>
	                    <div class="cryptoAccountModal-bottom" style="margin-top: 1px!important;">
	        
	                        <canvas ref="qrcodeCanvas" size="260" class="isCode"></canvas>
	                        <!-- <div class="qr" id="qrShowDiv" style="margin-left: 35px;">
	                        </div> -->
	                    </div>
	                    <p class="block-text">Alternatively, you can type the below secret key</p>
	                    <div class="default-input">
	                        <input type="text" readonly="" id="secretKeyValue" v-model="secretKeyValue">
	                    </div>
	                    <p class="block-text" style="margin-top: 4px;">3.Type 2FA code below, shown on your virtual 2FA app
	                    </p>
	                    <div class="default-input">
	                        <input type="number" placeholder="Enter 6-digit code" maxlength="6" v-model="securityCode"
	                            id="sixDigitCode">
	                    </div>
	                    <button type="button" class="button" @click="enable2FA">Enable 2FA</button>
	                    <p class="description">If you do not receive the 6-digit code within 30 seconds, you can <a
	                            @click="reloadPage">try it again</a></p>
	                </div>
	            </div>
	        </div>
			</div>
</template>

<script>
	import MainContentComp from '@/components/section/mainComp.vue'
	import { openValid, openGoogleAuth, getGoogleAuth } from '@/api/setting'
	import { getUserInfo } from '@/api/user'
	import QRCode from 'qrcode'
export default {
    name: 'dashboardComsp',
    inject: ['reload'],
    components: {
        MainContentComp
    },
    props: {
        msg: String
    },
    computed: {
        // currentPath: {
        //   get() {
        //     console.log("this.$route.path: " + this.$route.path)
        //     return this.$route.path; // 获取当前路由路径
        //   }
        // }
    },
    watch: {
        // '$route': { // $route可以用引号，也可以不用引号  监听的对象
        //     handler(to, from) {
        //         this.currentPath = to.path
        //         const pathList = [
        //             '/transactions',
        //             '/Setting'
        //         ]
        //         // console.log("watch router: ", this.currentPath)
        //         if (pathList.indexOf(this.currentPath) > -1) {
        //             this.getUserBankBalance()
        //         }
        //     },
        //     deep: true, // 深度观察监听 设置为 true
        //     immediate: true, // 第一次初始化渲染就可以监听到
        // }
    },
    created() { },
    mounted() {
        this.showEnable2FAPanel()
    },
    data() {
        return {
            emailAddress:'',
            isEnable2FA: true,
            isShowEnable2FAPanel: true,
            isShowDisable2FAPanel: true,
            secretKeyValue: '',
            securityCode: '',
            qrCodeContent: 'qrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContentqrCodeContent'
        }
    },
    methods: {
        initData() {
            this.$pu.showLoading()
            // Promise.all().then(results=>{
    
            // })
            getUserInfo().then(res => {
                this.$pu.hideLoading()
                if (res.code === 200) {
                    this.isEnable2FA = res.data.hasGoogleAuth
                    this.emailAddress = res.data.email
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        },
        generateQRCode() {
            const canvas = this.$refs.qrcodeCanvas
            QRCode.toCanvas(canvas, this.qrCodeContent, {
                width: 260,
                margin: 1,
                color: {
                    dark: '#5868bf',
                    light: 'ffffff'
                }
            }, error => {
                if (error) console.error(error)
            })
        },
        reloadPage() {
            this.reload()
        },
        toggleEnable2FAPanel(val) {
            this.isShowEnable2FAPanel = val
        },
        beforeEnableChanged(val) {
            if (this.isEnable2FA) {
                this.isShowDisable2FAPanel = true
            } else {
                this.showEnable2FAPanel()
            }
            return false
        },
        onEnableChanged(val) {
            // if (val) {
            //     this.isShowEnable2FAPanel = true
            // }
        },
        // showDisable2FAPanel(){
        //         this.isShowDisable2FAPanel = true
        // },
        showEnable2FAPanel() {
            this.$pu.showLoading()
            getGoogleAuth().then(res => {
                if (res.code === 200) {
                    this.secretKeyValue = res.data.secret
                    this.qrCodeContent = res.data.googleAuthQrcodeText
                    this.$pu.hideLoading()
                    this.isShowEnable2FAPanel = true
                    this.generateQRCode()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        },
        enable2FA() {
            this.$pu.showLoading()
            const params = {
                securityCode: this.securityCode
            }
            openGoogleAuth(params).then(res => {
                this.$pu.hideLoading()
                if (res.code === 200) {
                    this.$message.success('2FA enabled successfully!')
                    this.isShowEnable2FAPanel = false
                    let _this = this

                    this.$pu.showLoading()
                    getUserInfo().then(res => {
						this.$pu.hideLoading()
						if (res.code === 200) {
							let userInfo = res.data
							if (userInfo.registerToBank == false) {
								this.$router.push('/registration');
							} else if (userInfo.hasGoogleAuth == false) {
								this.$router.push('/dashboard');
							} else {
								this.$router.push('/accountDashboard')
								// this.gooGelcode = false
							}
						} else {
							this.$message.error(res.msg)
						}
					})

                    // setTimeout(() => {
                    //     this.$router.push('/registration');
                    // }, 1500);
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        },
        // disableFA() {
        //     this.$pu.showLoading()
        //     const params = {
        //         "openGoogleValid": false
        //     }
        //     openValid(params).then(res => {
        //         this.$pu.hideLoading()
        //         if (res.code === 200) {
        //             this.$message.success('2FA disabled successfully!')
        //             this.isShowDisable2FAPanel = false
        //             let _this = this
        //             setTimeout(() => {
        //                 this.$router.push('/registration');
        //             }, 1500);
        //         } else {
        //             this.$message.error(res.msg)
        //         }
        //     }).catch(error => {
        //         this.$pu.hideLoading()
        //         this.$message.error(error)
        //     })
        // }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/main.css';
@import '@/assets/css/cryptoAccount.css';

.settingsPage .security-authentication {
    align-items: center
}

.authentication-checkbox {}

.cryptoAccountModal-bottom {
    display: flex;
    align-items: center;
    justify-items: center;
}
.isCode{
	margin:0 auto;
	margin-left: 35px;
}
</style>