import request from "@/utils/request";
import Store from '@/store/index'
//联系人分页
export const getTransactionPage = (data) =>
  request({
    url: "/account/accountStatementFromBank",
    method: "post",
    data,
  });
// 通过用户登录邮箱转账
export const bankTransfer = (data) =>
  request({
    url: "/transfer/bank_transfer",
    method: "post",
    data,
  });
// 查询用户所有联系人
export const contactList = () =>
  request({
    url: "/contact/list",
    method: "GET",
  });
// 添加联系人-邮箱
export const addContact = (data) =>
  request({
    url: "/contact/addContact",
    method: "post",
    data,
  });
// 查询用户已拥有的币种
export const coinNames = () =>
  request({
    url: "/coin/supportCoinNames",
    method: "GET",
  });
// globalAccount
export const globalAccount = () => {
  return new Promise((resolve, reject) => {
    request({
      url: "/account/globalAccount",
      method: "GET",
    }).then(res => {
      if (res.code === 200) {
        if (res.data && res.data.length > 0) {
          Store.commit('set_has_global_account', true)
        } else {
          Store.commit('set_has_global_account', false)
        }
      }
      resolve(res)
    })
  })
}
export const downloadCsv = (data) =>
  request({
    url: "/account/downloadCsv",
    method: "post",
    data,
    responseType: 'blob'
  });
export const downloadPdf = (data) =>
  request({
    url: "/account/downloadPdf",
    method: "post",
    data,
    responseType: 'blob'
  });
