<template>
    <MainContentComp header-title="Recipients">
        <section class="transfer-add block">
            <div class="wrapper">
                <h2 class="content__title">Add Recipient Form</h2>
                <div class="block">
                    <div action="">
                        <h3 class="subtitle">Bank Details</h3>
                        <div class="items">
                            <div class="default-input item">
                                <label>Email Address</label>
                                <div class="input">
                                    <input type="text" placeholder="Email Address" v-model="emailAddress"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required="">
                                </div>
                            </div>
                            <div class="default-input item">
                                <label>Full Name of the Account Holder</label>
                                <div class="input">
                                    <input type="text" placeholder="Full Name" v-model="fullName">
                                </div>
                            </div>
                            <div class="default-input item">
                                <label>Phone number</label>
                                <div class="input">
                                    <input type="text" placeholder="Phone number" v-model="phoneNumber">
                                </div>
                            </div>
                        </div>
                        <div class="buttons">
                            <button type="button" class="button" @click="addContactP2P">Save</button>
                            <a @click="backP2P" class="back">
                                Back
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </MainContentComp>
</template>

<script>
import MainContentComp from '@/components/section/mainComp.vue'
import { addRecipient } from '@/api/recipient'
export default {
    name: 'FeeTable',
    components: {
        MainContentComp
    },
    props: {
        msg: String
    },
    data() {
        return {
            emailAddress: '',
            fullName: '',
            phoneNumber: '',
        }
    },
    methods: {
        addContactP2P() {
            this.addContact()
        },
        backP2P() {
            this.$router.back()
        },
        addContact() {
            let emailAddress = this.emailAddress
            if (emailAddress == "") {
                this.$message.error('Please enter the emailAddress!');
                return;
            } else if (!(emailAddress == "")) {
                const regex_pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!(regex_pattern.test(emailAddress))) {
                    this.$message.error('Please enter the valid email address! e.g.xyz@gmail.com');
                    return;
                }
            }

            let fullName = this.fullName
            if (fullName == "") {
                this.$message.error('Please enter the fullName!');
                return;
            } else if (!(fullName == "")) {
                const regex_pattern = /^[A-Za-z ]+$/;
                if (!(regex_pattern.test(fullName))) {
                    this.$message.error('Full name can contain only Alpha Characters and space!');
                    return;
                }
            }

            let phoneNumber = this.phoneNumber
            // var phoneNumber = document.getElementById("phoneNumber").value;
            if (phoneNumber == "") {
                this.$message.error('Please enter the phoneNumber!');
                return;
            } else if (!(phoneNumber.includes("+"))) {
                this.$message.error('Please enter the valid country code with (+) sign!');
                return;
            } else if (!(phoneNumber == "")) {
                const regex_pattern = /^[0-9+]+$/;
                if (!(regex_pattern.test(phoneNumber))) {
                    this.$message.error('Phone Number can contain only digits and + sign!');
                    return;
                }
            }

            if ((phoneNumber.length) < 8) {
                this.$message.error('Minimum digits allowed in phoneNumber is 08!');
                return;
            }

            if ((phoneNumber.length) > 15) {
                this.$message.error('Maximum digits allowed in phoneNumber is 15!');
                return;
            }
            const params = {
                addToBank: true,
                email: this.emailAddress,
                name: this.fullName,
                phone: this.phoneNumber
            }
            console.log("params: ", JSON.stringify(params, null, '\t'))
            this.$pu.showLoading()
            addRecipient(params).then(res => {
                this.$pu.hideLoading()
                if (res.code === 200) {
                    this.$message.success('Contact created successfully!');
                    this.emailAddress = ''
                    this.fullName = ''
                    this.phoneNumber = ''
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.css';

.default-input {
    .input {
        border: 0;
        padding: 0;
    }
}
</style>