import request from "@/utils/request";
import Store from '@/store/index'
export const getUserBankBalance = () => {
    return new Promise((resolve, reject) => {
        request({
            url: "/account/userBankBalance",
            method: "get"
        }).then(res => {
            if (res.code === 200) {
                const userInfo = {
                    userBankBalance: res.data.amount
                }
                Store.commit('set_assets_info', userInfo)
            }
            resolve(res)
        })
    })
}

export const addAccount = (data) =>
    request({
        url: "/stylopay/userinfo/addAccount",
        method: "post",
        data
    })

export const getBankUserinfo = (data) =>
    request({
        url: "/stylopay/userinfo/get",
        method: "get",
        data
    })