<template>
    <MainContentComp headerTitle="Recipients">
        <section class="recipients block" id="transferHideShow">
            <div class="wrapper">
                <div class="account__content-top cryptoAccount-top">
                    <h2 class="content__title">Recipient List</h2>
                    <div class="buttons">
                        <a href="/addP2PTransferRecipient" class="button">Add Recipient</a>
                    </div>
                </div>
                <table class="recipients-table">
                    <thead>
                        <tr>
                            <th id="maskedcard">Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody id="myTable2">
                        <tr v-for="(item, index) in dataList" :key="item.id">
                            <td id="maskedcard0" value="ding li guo">
                                {{ item.name }}
                            </td>
                            <td>
                                {{ item.email }}
                            </td>
                            <td>
                                {{ item.phone }}
                            </td>
                            <td>
                                <a class="send button3" @click="deleteRecipient(item.id)">RemoveRecipient</a>
                            </td>
                            <td>
                                <a @click="toTransferPage(item.email)" class="send">SendMoney<span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8"
                                            viewBox="0 0 16 8">
                                            <g>
                                                <g transform="rotate(-90 8 4)">
                                                    <path class="banner__arrow" fill="#fff"
                                                        d="M7-3a1 1 0 0 1 2 0V8.586l1.293-1.293a1 1 0 0 1 1.414 1.414l-2.95 2.95a1 1 0 0 1-1.464.05l-3-3a1 1 0 0 1 1.414-1.414L7 8.586z">
                                                    </path>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </MainContentComp>
</template>

<script>
import MainContentComp from '@/components/section/mainComp.vue'
import { getRecipientList, removeRecipient } from '@/api/recipient'
export default {
    name: 'FeeTable',
    components: {
        MainContentComp
    },
    props: {
        msg: String
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            dataList: [],
            isRequesting: false
        }
    },
    created() { },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            this.refresshData()
        },
        refresshData() {
            this.page = 1
            this.dataList = []
            this.loadPage('refresh')
        },
        loadmore() {
            this.loadPage('loadmore')
        },
        loadPage(mode = 'loadmore') {
            const params = {
                page: mode === 'loadmore' ? (this.page + 1) : 1,
                pageSize: 10
            }
            this.$pu.showLoading()
            getRecipientList(params).then(res => {
                this.$pu.hideLoading()
                // res = res.data
                // console.log("res: " + JSON.stringify(res,null,'\t'));
                if (res.code === 200) {
                    if (mode === 'loadmore') {
                        if (res.data && res.data.length > 0) {
                            // this.page = res.data.current
                            this.dataList = this.dataList.concat(res.data)
                        }
                    } else {
                        if (res.data && res.data.length > 0) {
                            // this.page = res.data.current
                            this.dataList = res.data
                        }
                    }
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        },
        deleteRecipient(val) {
            this.$pu.showLoading()
            removeRecipient(val).then(res => {
                this.$pu.hideLoading()
                // res = res.data
                if (res.code === 200) {
                    this.refresshData()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(error => {
                this.$pu.hideLoading()
                this.$message.error(error)
            })
        },
        toTransferPage(val) {
            this.$router.push({
                path: '/transferP2Pmm',
                query: {
                    email: val
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/select2.min.css';
@import '@/assets/css/recipients.css';
@import '@/assets/css/main.css';


.button3:hover {
    background-color: #f44336 !important;
    color: white !important;
}

.button:hover {
    background-color: #3246d3 !important;
    color: white !important;
}

.button3 {
    color: red !important;
    background-color: #f5f6fa !important;
}

.send {
    font-size: 13px !important;
    padding: 12px 19px !important;
}

.noTextTransaformation {
    color: #e4e4f3 !important;
}

.select2-container--default .select2-selection--single {
    border-radius: 9px;
    border: 2px solid #eff1f7;
    height: 52px;
    color: #3246d3;
    line-height: 28px;
    box-sizing: 100px;
    padding: 8px 20px 10px;
    font-style: inherit;
    font-weight: bold;
    text-overflow: ellipsis;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #4f5eed;
    line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    top: 92%;
}

.bigdrop {
    width: 90px !important;
}

.widthChange {
    max-width: 11% !important;
}

/* .select3{
			background: #fff;
		    border-radius: 10px;
		    font-weight: 500;
		    font-size: 15px;
		    line-height: 20px;
		    color: #1a1b26;
		    width: 100%;
		    padding: 15px 10px;
		    font-family: "GothamPro", sans-serif;
		    border: 2px solid #eff1f7;
		    text-align: center;
	   } */
/* 
.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 9px;
	border: 2px solid #eff1f7;
    height: 52px;
    top: 92%;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #093ae6;
    line-height: 27px;
    top: 92%;
    font-style: inherit;
    font-weight: bold;
    box-sizing: 100px;
    box-shadow: none;
} */


#getCard.active a {
    color: #000 !important;
    /* Change to black color for the active page */
}

.sidebar__list-item:not(.active) .svg__gray {
    stroke: #808080;
    /* Change this color to your desired color */
}

.sidebar__list-item.active a {
    stroke: #ffa720;
    /* Change this color to your desired color */
}

.active {
    pointer-events: auto;
}

@media (max-width: 768px) {
    .recipients-table {
        thead {
            display: none;
        }

        tbody {
            tr {
                margin-bottom: 10px;
                margin-top: 10px;
                display: block;
                // width: calc(100% - 20px);
                float: left;
                border: 1px solid #dedede;
                padding: 0 10px;
                width: 100%;
                border-radius: 10px;
            }

            td {
                display: flex;
                justify-content: flex-end;
                padding: 10px 0;
                font-size: 14px;
                min-height: 39px;
            }
        }
    }
}
</style>