import request from "@/utils/request";
// 用户银行卡列表
export const userCards = () =>
	request({
		url: "/card/userCards",
		method: "get"
	});
// 分配物理卡
export const assignPCard = (data) =>
	request({
		url: "/card/assignPCard",
		method: "post",
		data,
	});
// 设置银行卡密码
export const setPin = (data) =>
	request({
		url: "/card/setPin",
		method: "post",
		data,
	});
// 创建虚拟卡
export const getVCard = (data) =>
	request({
		url: "/card/getVCard",
		method: "post",
		data,
	});
// 激活卡
export const activeCard = (id) =>
	request({
		url: "/card/activeCard/" + id,
		method: "post"
	});
// 锁定卡-解锁卡
export const opCard = (data) =>
	request({
		url: "/card/opCard",
		method: "post",
		data
	});
