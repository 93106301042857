<template>

  <el-dialog class="mobilPhone" style="border-radius: 20px;margin-top: 15vh;" width="600px" :visible="show"
    :header="false" :closeBtn="false" @onClose="onClose">
    <span slot="label" class="disal">Top-up Bank</span>
    <div class="transfers-send">
      <!-- <h2 class="content__title transfers__title">Top-up Bank</h2> -->
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <div>
          <el-form-item label="Coin" prop="coinId">
            <el-select class="svg-container" v-model="form.coinId" placeholder="Please select" @change="changeCoin">
              <template #prefix>
                <span class="flex">
                  <img :src="choiceCoin.coinLogo || require('@/assets/imgs/icon_btc.png')" width="24" height="24" />
                </span>
              </template>
              <el-option v-for="item in coinList" :key="item.id" :label="item.coinLink" :value="item.id">
                <div class="disal">
                  <img :src="item.coinLogo" width="24" height="24" />
                  <span class="select-item" style="font-size: 14px;">{{ item.coinLink }}</span>
                </div>
              </el-option>
            </el-select>
            <span style="font-size: 12px;">
              Available amount {{ this.assetInfo.balance }} {{ this.choiceCoin.coinLink }}
            </span>
          </el-form-item>
          <el-form-item label="Recharge Amount" prop="amount">
            <el-input v-model="form.amount" placeholder="Please enter the recharge amount"></el-input>
          </el-form-item>
        </div>
        <div class="dialog-footer">
          <el-button type="primary" @click="onSure('form')">
            Confirm
          </el-button>
        </div>
      </el-form>
    </div>
    <div class="modal cryptoAccountModal active" data-modal="ActiveCardModal2FA" v-if="isCode">
      <div class="modal-block">
        <div class="modal-top">
          <div class="tabs">
            <h1 class="modal-title">
              Authentication
            </h1>
          </div>
          <div class="modal-close buttonClose" @click="isCode = false">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 11L11 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
              <path d="M11 11L1 1" stroke="#3246D3" stroke-width="1.5" stroke-linecap="round"></path>
            </svg>
          </div>
        </div>
        <div class="cryptoAccountModal-main active">
          <div class="cryptoAccountModal-top">
            <p style="font-size:15px;">
              <strong>
                Please enter the 2FA code from your virtual 2FA device
              </strong>
            </p>
            <div class="info">
            </div>
          </div>
          <div class="cryptoAccountModal-bottom">
            <div class="default-input item">
              <input type="text" v-model="googleCode" placeholder="Enter the six digit code here" autocomplete="off"
                maxlength="6" required="">
            </div>
            <button type="button" @click="SubmitCode" class="button">Submit</button>
            <div class="info">
            </div>
          </div>
        </div>
      </div>

    </div>
  </el-dialog>

</template>

<script>
import { getRechargeCoins } from '@/api/coin'
import { recharge, coinDes } from '@/api/wallet'
export default {
  name: 'RechargeDialog',
  emits: ["visibleEvent"],
  components: {
  },
  props: {
    msg: String,
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateAmount = (rule, value, callback) => {
      const num = /(^[0-9]\d*(\.\d{1,100})?$)|(^0(\.\d{1,100})?$)/;
      if (!num.test(value)) {
        callback(new Error('Please enter the correct format.'))
      } else {
        callback()
      }
    }
    return {
      amount: '',
      remark: '',
      email: '',
      googleCode: '',
      contentList: [],
      coinList: [{
        coinName: 'USD',
        coinLogo: 'https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/flags/flag/USD.svg'
      }],
      coinName: 'USD',
      coinLogo: 'https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/flags/flag/USD.svg',
      dialogVisible: false,
      isCode: false,
      form: {
        "amount": "",
        "coinId": '',
        "googleCode": "",
        "type": 0
      },
      choiceCoin: {
        coinName: '',
        coinLogo: '',
      },
      assetInfo: {
        "balance": "",
        "coinLink": "",
        "coinName": "",
        "to_usdt_rate": "",
        "total": "",
        "usdt": "",
        "usdtBalance": ""
      },
      rules: {
        amount: [
          {
            required: true,
            message: 'Please enter recharge amount',
            trigger: "blur",
          },
          { required: true, validator: validateAmount, trigger: "change" }
        ],
        coinId: [
          {
            required: true,
            message: 'Please select recharge coin',
            trigger: "blur",
          }
        ],
      }
    }
  },
  computed: {
    assetsInfo() {
      return this.$store.state.assets
    }
  },
  mounted() {
    this.getCoinNames()
  },
  methods: {
    toPage(path) {
      this.$router.push(path)
    },
    getCoinNames() {
      getRechargeCoins({ type: 0 }).then(res => {
        this.coinList = res.data
      })
    },
    confirePay() {
      this.googleCode = ''
      this.dialogVisible = false
      this.isCode = true
    },
    SubmitCode() {
      this.$pu.showLoading()
      let data = {
        "amount": this.form.amount,
        "coinId": this.form.coinId,
        "googleCode": this.googleCode,
        "type": 0
      }
      console.log("data: " + JSON.stringify(data, null, '\t'))
      recharge(data).then(res => {
        this.$pu.hideLoading()
        if (res.code == 200) {
          this.isCode = false
          this.googleCode = ''
          this.$message.success(res.msg)
          this.$emit("visibleEvent", false);
          // setTimeout(() => {
          //   this.$router.push('/transactions')
          // }, 1000);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    Confirm() {
      if (!this.form.amount) {
        this.$message.error('Please enter the Amount!');
        return
      }
      this.dialogVisible = true
    },
    changeCoin(data) {
      console.log(data)
      // data = parseInt(data) - 1
      // console.log(this.option)
      let obj = this.coinList.find(item => item.id === data)
      // console.log(obj)
      this.choiceCoin.coinName = obj.coinName;
      this.choiceCoin.coinLink = obj.coinLink;
      this.choiceCoin.coinLogo = obj.coinLogo;
      this.form.coinId = obj.id
      // this.form.coinName = obj.coinName;
      // this.form.coinNetWork = obj.coinLink
      // this.getcoinBalance(this.form);
      // this.getCoinLink(this.choiceCoin.coinName);
      this.getCoinBalance()
    },
    onSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return
        }
        console.log(this.form.amount + " : " + this.assetInfo.balance)
        if (parseFloat(this.form.amount) > parseFloat(this.assetInfo.balance)) {
          this.$message.error('Insufficient account balance')
          return
        }
        this.confirePay()
      });
    },
    getCoinBalance() {
      // this.$pu.showLoading()
      coinDes({
        "coinLink": this.choiceCoin.coinLink,
        "coinName": this.choiceCoin.coinName
      }).then(res => {
        this.assetInfo = res.data
      }).finally(() => {
        // this.$pu.hideLoading()
      })
    },
    confirePay() {
      this.googleCode = ''
      this.dialogVisible = false
      this.isCode = true
    },
    // 取消
    onClose() {
      this.$emit("visibleEvent", false);
    },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 480px) {
  .google-code-num {
    white-space: normal;
    overflow-wrap: break-word;
    width: 90%;
  }
}

:deep(.el-tabs__header) {
  display: none;
}

:deep(.dialog-footer) {
  /* float: right; */
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
  margin-top: 20px;
}

.disal {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #6F7D8D;
}

.el-tabs__item {
  font-size: 24px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #6F7D8D;
}

.el-tabs__item.is-active {
  color: #31363e;
}

.el-tabs__nav-wrap::after {
  height: 0;
}

.el-tabs__active-bar {
  width: 76px;
  height: 4px;
  background: #786234;
}

.change-form {
  background: #eff6ff;
  padding: 30px;
  margin: 20px 0;
  border-radius: 10px;
}

.el-dialog__body {
  padding: 10px 20px;
}

.el-input-group__append {
  background-color: #fff;
  border: none;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #b9bfca;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    height: 46px;
    width: 1px;
    background-color: #eee;
    top: 12px;
    left: 0;
  }
}

.select-item {
  // font-size: 18px;
  // font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  // font-weight: 400;
  // color: #31363e;
  margin-left: 10px;
}

.change-form.el-form-item.col2 .el-input {
  width: 46%;
}

.change-form.el-form-item.col2 .jiange {
  padding: 0 3%;
  line-height: 56px;
}

.change-form .svg-container1 .el-input__inner {
  padding-left: 15px !important;
}

::v-deep {
  .el-input-group__append::after {
    background-color: transparent !important;
  }
}

.change-form .account-balance {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #31363E;
  margin-right: 20px;
}

.change-form .account-num {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #31363E;
}

.change-form .account-num1 {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(49, 54, 62, 0.6);
}

.change-form .google-code {
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
}

.change-form .google-address {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #b9bfca;
}

.change-form .two-between1 {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 15px;
  padding: 15px;
  display: flex;
  align-items: center;

}

.change-form .google-code-num {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #31363e;
}

:deep(.el-select__wrapper) {
  height: 56px !important;
  line-height: 56px !important;
  border-color: transparent;
  border-radius: 8px;
  box-shadow: none;
}

:deep(.el-select__wrapper.is-disabled) {
  box-shadow: none;
}

:deep(.el-input__wrapper) {
  height: 56px !important;
  line-height: 56px !important;
  border-color: transparent;
  border-radius: 8px;
  box-shadow: none;
}

:deep(.el-input-group__append) {
  background-color: #fff;
  border: none;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #b9bfca;
  cursor: pointer;

}

.common-color {
  color: #165BA4 !important;
}

.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}

:deep(.el-input-group__append) {
  box-shadow: none;
}
</style>
