<template>
    <section class="header">
        <div class="wrapper">
            <div class="header__content">
                <div class="header__sidebar" @click="toggleSidebarOpen">
                    <a>
                        <span>

                        </span>
                    </a>
                </div>
                <h2 class="header__title" id="headerHeading">{{ headerTitle }}</h2>

                <a href="" class="addAccountButton" :class="hasGlobalAccount?'displayNone':''" id="addAccountButtonId"
                    @click="validationAddAccount">
                    <span class="icon">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 13L7 13" stroke="#3246D3" stroke-width="1.75">

                            </path>
                            <path d="M13 7V19" stroke="#3246D3" stroke-width="1.75">

                            </path>
                        </svg>
                    </span>
                    <p>+ Add account</p>
                </a>
                <div class="header__account">
                    <div class="header__text">
                        <div class="header__text-bold" id="adminNameDiv">{{ userInfo.name }}</div>
                        <div class="header__text-thin" id="billingCityDiv">{{ personalInfo.billingCity }}</div>
                    </div>
                    <div class="header__account-avatar" @click="toggleAccountDrop">
                        <a href="#">
                            <img :src="require('@/assets/img/avtarr.jpg')" alt="">
                            <span class="arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6">
                                    <g>
                                        <g class="account__arrow" transform="rotate(-180 4 3)">
                                            <g>
                                                <path fill="#1a1b26"
                                                    d="M1.707 5.121A1 1 0 0 1 .293 3.707L4 0l3.707 3.707a1 1 0 0 1-1.414 1.414L4 2.828z">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </a>
                        <div class="header__account-drop" :style="{ display: isShowAccountDrop ? 'block' : 'none' }">
                            <ul>
                                <li class="header__account-item profile"><a href="profile">Profile</a></li>
                                <li class="header__account-item account-details"><a href="/accountDetails">Account
                                        Details </a></li>
                                <li class="header__account-item logout" @click="logout">
                                    <a>
                                        <span class="icon">
                                            <img :src="require('@/assets/img/icons/logOut.svg')" alt="">
                                        </span>
                                        Log Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="header__notification header__notification-activ ">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import local from "@/utils/local";
import { getUserInfo, getPersonalInfo } from '@/api/user'
import { getUserBankBalance } from '@/api/bankAccount'
import {
	globalAccount
} from '@/api/transaction'
export default {
    name: "SectionHeader",
    props: {

        headerTitle: {
            type: String,
            default: ''
        },
        adminName: {
            type: String,
            default: ''
        },
        billingCity: {
            type: String,
            default: ''
        },
        // 检测类型 + 其他验证
        // numberProp: {
        // 	type: Number,
        // 	default: 0,
        // 	validator: function(value) {
        // 		return value >= 0
        // 	}
        // },
        // stringProp: {
        // 	type: String,
        // 	default: '',
        // 	required: false,
        // },
        // objectProp: {
        // 	type: Object,
        // 	required: false,
        // 	default: function() {
        // 		return {};
        // 	}
        // },
        // arrayProp: {
        // 	type: Array,
        // 	required: false,
        // 	default: function() {
        // 		return [];
        // 	}
        // },
    },
    watch: {
        propName: {
            handler(newVal, oldVal) {

            }
        }
    },
    data() {
        return {
            isShowAccountDrop: false
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo
        },
        hasGlobalAccount() {
            return this.$store.state.hasGlobalAccount
        },
        personalInfo() {
            return this.$store.state.personalInfo
        }
    },
    beforeCreate() { },
    created() { },
    beforeMount() { },
    //挂载到实例上去之后调用。
    //注意：此处并不能确定子组件被全部挂载，如果需要子组件完全挂载之后在执行操作可以使用$nextTick
    mounted() { 
        let userInfo = this.$store.state.userInfo
        if("{}" ==JSON.stringify(userInfo)){
            getUserInfo()
        }
        let assets = this.$store.state.assets
        if("{}" ==JSON.stringify(assets)){
            getUserBankBalance()
        }
        let personalInfo = this.$store.state.personalInfo
        if("{}" ==JSON.stringify(personalInfo)){
            getPersonalInfo()
        }
        let hasGlobalAccount = this.$store.state.hasGlobalAccount
        if(!hasGlobalAccount){
            globalAccount()
        }
    },
    //适用范围：仅H5平台支持
    beforeUpdate() { },
    //适用范围：仅H5平台支持
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    methods: {
        toPage(path) {
            this.$router.push(path)
        },
        toggleAccountDrop() {
            this.isShowAccountDrop = !this.isShowAccountDrop
        },
		loginOut(){
			local.remove("tk");
			this.$router.push('/login');
		},
        toggleSidebarOpen() {
            console.log('toggleSidebarOpen')
            this.$emit('toggleSidebarOpen')
        },
        logout() {
            this.$store.commit('logout')
            local.set("tk", null);
            this.$router.replace('/login')
        },
        validationAddAccount(){
            this.$router.push('/addAccount')
        }

    }
}
</script>

<style lang="scss"></style>