<template>
    <section class="banner">
        <div class="wrapper">
            <div class="banner__content">
                <div class="banner__info">
                    <div class="banner__balance">
                        <div class="banner__balance-icon">
                            <img :src="require('@/assets/img/balance_icon.svg')" alt="">
                        </div>
                        <div class="banner__balance-num" id="mainBalanceDiv">$ {{ assetsInfo.userBankBalance }}</div>
                    </div>
                    <div class="banner__balance-description">
                        <div class="banner__balance-text">
                            <span class="banner__balance-text1">Your total balance
                                estimated</span> <span class="banner__balance-in"> in</span> <span>
                                <form action="" class="banner__form">
                                    <div class="banner-down">

                                        <div class="banner-class">
                                            <img
                                                src="https://stylopay-sandbox-us-east-1-dev-dump.s3.amazonaws.com/flags/flag/USD.svg">USD
                                        </div>
                                    </div>
                                </form>
                            </span> <span class="nowrap">

                            </span>
                        </div>

                        <!-- <div class="banner__balance-text">
                            <span class="nowrap">at 10.05.20 14:15</span>
                        </div> -->
                    </div>
                </div>
                <div class="banner__btns">
                    <div class="banner__btns-sm">
                        <!-- <div class="banner__btn-sm">
                  <a href="transfers-add.html">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <g>
                    <g>
                    <path class="banner__btn-svg" fill="#3246d3" d="M9 1a1 1 0 0 1 2 0v8h8a1 1 0 0 1 0 2h-8v8a1 1 0 0 1-2 0v-8H1a1 1 0 0 1 0-2h8z"/>
                </g>
                </g>
                </svg>
                </a>
                </div> -->
                        <div class="banner__btn-sm">
                            <!--                  <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
                                <g>
                                <g>
                                <path class="banner__btn-svg" fill="#3246d3" d="M18 11.004a8.962 8.962 0 0 0-1.967-5.617l-1.426 1.426a7 7 0 0 1-7.6 10.903l.641-.3.103-.055a1 1 0 0 0-.948-1.757l-2.719 1.268-.103.055a1.001 1.001 0 0 0-.466.977l-.132.132a9 9 0 0 0 .22.17l1.265 2.714.056.102a1 1 0 0 0 1.273.381l.103-.055a1 1 0 0 0 .381-1.274l-.2-.427c.8.232 1.645.357 2.519.357a9 9 0 0 0 9-9zM11.568 4.49l-.216.1-.103.056a1 1 0 0 0 .948 1.757l2.72-1.268.102-.055a1 1 0 0 0 .38-1.273l-1.267-2.72-.055-.102a1 1 0 0 0-1.274-.381L12.7.659a1 1 0 0 0-.38 1.274l.408.877A8.97 8.97 0 0 0 9 2.004 9 9 0 0 0 1.968 16.62l1.425-1.426A7 7 0 0 1 11.568 4.49z"/>
                            </g>
                            </g>
                            </svg>
                            </a>
 -->
                        </div>
                    </div>


                    <div class="banner__btn-bg" @click="toPage('/transferP2Pmm')">
                        <a href="javascript:void(0)">Send money <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8">
                                    <g>
                                        <g transform="rotate(-90 8 4)">
                                            <path class="banner__arrow" fill="#fff"
                                                d="M7-3a1 1 0 0 1 2 0V8.586l1.293-1.293a1 1 0 0 1 1.414 1.414l-2.95 2.95a1 1 0 0 1-1.464.05l-3-3a1 1 0 0 1 1.414-1.414L7 8.586z">
                                            </path>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "SectionBanner",
    props: {
        // 检测类型 + 其他验证
        // numberProp: {
        // 	type: Number,
        // 	default: 0,
        // 	validator: function(value) {
        // 		return value >= 0
        // 	}
        // },
        // stringProp: {
        // 	type: String,
        // 	default: '',
        // 	required: false,
        // },
        // objectProp: {
        // 	type: Object,
        // 	required: false,
        // 	default: function() {
        // 		return {};
        // 	}
        // },
        // arrayProp: {
        // 	type: Array,
        // 	required: false,
        // 	default: function() {
        // 		return [];
        // 	}
        // },
    },
    watch: {
        propName: {
            handler(newVal, oldVal) {

            }
        }
    },
    data() {
        return {

        };
    },
    computed: {
        assetsInfo() {
            return this.$store.state.assets
        }
    },
    beforeCreate() { },
    created() { },
    beforeMount() { },
    //挂载到实例上去之后调用。
    //注意：此处并不能确定子组件被全部挂载，如果需要子组件完全挂载之后在执行操作可以使用$nextTick
    mounted() { },
    //适用范围：仅H5平台支持
    beforeUpdate() { },
    //适用范围：仅H5平台支持
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    methods: {
        toPage(path){
            this.$router.push(path)
        }
    }
}
</script>

<style lang="scss"></style>