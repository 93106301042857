import request from "@/utils/request";
import Store from '@/store/index'
export const getUserInfo = () => {
    return new Promise((resolve, reject) => {
        request({
            url: "/l/userInfo",
            method: "get"
        }).then(res => {
            if (res.code === 200) {
                const userInfo = {
                    hasGoogleAuth: res.data.hasGoogleAuth,
                    name: res.data.bankUserName,
                    email: res.data.email
                }
                Store.commit('set_userInfo', userInfo)
            }
            resolve(res)
        })
    })
}
export const getPersonalInfo = () =>{
    return new Promise((resolve, reject) => {
        request({
            url: "/stylopay/userinfo/get",
            method: "get"
        }).then(res => {
            if (res.code === 200) {
                const info = {
                    billingCity: res.data.billingCity
                }
                Store.commit('set_personal_info', info)
            }
            resolve(res)
        })
    })
}
    
export const updatePersonalInfo = (data) =>
    request({
        url: "/stylopay/userinfo/addUserInfo",
        method: "post",
        data
    })